import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { UpdatedPassword } from "../../redux/slice/auth";
import QrCode from "../QRCode/QrCode";
import DashButton from "../Buttons/DashButton";
import { useNavigate } from "react-router-dom";
import { useTheme } from '../../ThemeProvider';
import { Navbarvalue } from '../../HeaderValue';


export default function SecurityContent() {
  const [enableTwoFactorAuthenication, setenableTwoFactorAuthenication] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { updateValue } = Navbarvalue()

  const { isLoading } = useSelector((state) => state.user)
  const { theme, darkTheme, lightTheme } = useTheme();

  const onSubmit = async (data) => {
    if (data.password === data.passwordConfirm) {
      dispatch(UpdatedPassword(data))
        .then((res) => {
          if (res.payload.message === 'success') {
            toast.success("Successfully update password, Login with new password")
            reset();
            localStorage.clear();
            lightTheme()
            updateValue(null)

            navigate("/");
          }


        })
    } else {
      toast.error("password and confirm password are not same!")
    }
    console.log('passowords', data);
  }


  const QRCodeModalOpen = () => {
    setenableTwoFactorAuthenication(!enableTwoFactorAuthenication)
    console.log('modal open');
  }

  return (
    <div>


      <div className="row justify-content-center">
        <div className="col-md-11">
          <div className=" shadow-sm mt-4" >
            <div className="row">
              <div className="col-8">
                <p className={`bdms-title ${theme === 'light' ? 'dark-text' : 'light-text'}`}>Change Password</p>
                {/* <p
                  style={{ fontSize: "14px" }}
                  className="text-secondary fw-normal"
                >
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
                  dolorem consectetur, adipisicing elit.
                </p> */}
                <form style={{ fontSize: "14px" }} onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" style={{ color: `${theme === 'light' ? '#8f8e8e' : 'white'}` }} className={` form-label `}>
                      Current Password
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Current Password"

                      className="bdms-profile-input"
                      id="exampleInputEmail1"
                      {...register("passwordCurrent", {
                        required: 'Current password is required'
                      })}

                    />
                    {errors.passwordCurrent && errors.passwordCurrent.type === "required" && (
                      <div className="text-danger mt-2">Current password is required</div>
                    )}



                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className={` form-label `}
                      style={{ color: `${theme === 'light' ? '#8f8e8e' : 'white'}` }}
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      placeholder="Enter New Password"

                      className="bdms-profile-input"
                      id="exampleInputPassword1"
                      {...register("password", {
                        required: 'password is required'
                      })}

                    />
                    {errors.password && errors.password.type === "required" && (
                      <div className="text-danger mt-2">password is required</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className={` form-label `}
                      style={{ color: `${theme === 'light' ? '#8f8e8e' : 'white'}` }}
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Confirm Password"

                      className="bdms-profile-input"
                      id="exampleInputPassword1"
                      {...register("passwordConfirm", {
                        required: 'confirm password is required'
                      })}

                    />
                    {errors.passwordConfirm && errors.passwordConfirm.type === "required" && (
                      <div className="text-danger mt-2">confirm password is required</div>
                    )}
                  </div>



                  <DashButton
                    type={"submit"}
                    color='dark'
                    label={'Save'}
                    disabled={isLoading}

                  />
                </form>
              </div>
              <div className="col-4">
                <div
                  className="bg-primary bg-opacity-25  p-3 rounded-5"
                  style={{ fontSize: "12px" }}
                >
                  <p className={` fs-6 ${theme === 'light' ? 'dark-text' : 'light-text'}`}>Rules for Password</p>
                  <p className={`  ${theme === 'light' ? 'dark-text' : 'light-text'}`}>
                    To create a new password, you have to meet all of the following
                    requirements:
                  </p>
                  <ul style={{ marginLeft: "-12px" }}>
                    <li className={` ${theme === 'light' ? 'dark-text' : 'light-text'}`}>Minimum 8 character </li>
                    <li className={`  ${theme === 'light' ? 'dark-text' : 'light-text'}`}>Atleast one special character</li>
                    <li className={`  ${theme === 'light' ? 'dark-text' : 'light-text'}`}>At least one number</li>
                    <li className={`  ${theme === 'light' ? 'dark-text' : 'light-text'}`}>Can’t be the same as a previous</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <QrCode ModalIsOpen={enableTwoFactorAuthenication} closeModal={QRCodeModalOpen} />

    </div>
  );
}









