// ThemeProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserDetail } from './redux/slice/auth';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const dispatch = useDispatch();
  const updatedData = useSelector((state) => state.user.userData.userDetail);

  useEffect(() => {
    dispatch(GetUserDetail());
  }, []);

  useEffect(() => {
    if (updatedData?.data?.theme_colour) setTheme(updatedData?.data?.theme_colour);
  }, [updatedData?.data]);

  useEffect(() => {
    // You can define your theme colors here
    const themeColors = {
      light: {
        '--background-color': '#f8f8f8',
        '--text-color': 'black',
        '--input-background-color': '#fff',
        '--input-border-color':'#C2C2C2',
        '--input-focus-color':'#C2C2C2',
        '--input-text-color': 'black',
        '--modal-background-color': '#152a4fd9',
        '--sidbar-highlight-color': '#F8F8F8',
        '--table-record-row-border':'#F8F8F8',
        '--table-record-row-inner':'white',
        '--card-background-color':'white',
        '--sidbar-background-color':'#131D32',
        '--icon-highlight-color':'#041c2b',
        '--search-input-color':'black',
        '--modal-body-background':'#F8F8F8',
        '--modal-icon-color':'red',
        '--vertical-line-color':'#CBCBCB',
        '--add-new-folder-bar-background':'#EFEFEF',
        '--add-folder-icon':'white'
      
      },
      dark: {
        '--background-color': '#030814',
        '--text-color': 'white',
        '--input-background-color': '#030814',
        '--input-border-color':'#304267',
         '--input-focus-color':'white',
        '--input-text-color': 'white', // Change this color as needed
        '--modal-background-color':'#030814a8', // Change this color as needed
        '--sidbar-highlight-color': '#030814',
        '--table-record-row-border':'#030814',
        '--table-record-row-inner':'#101A2F',
        '--card-background-color':'#101A2F',
        '--sidbar-background-color':'#121E37',
        '--icon-highlight-color':'white',
        '--search-input-color':'#ADA2D4',
        '--modal-body-background':'#131D32',
        '--modal-icon-color':'#6279A8',
        '--vertical-line-color':'white',
        '--add-new-folder-bar-background':'#101A2F',
        '--add-folder-icon':'black'
      },
    };

    // Set the CSS variables based on the selected theme
    const root = document.documentElement;

    Object.entries(themeColors[theme]).forEach(([key, value]) => {
      root.style.setProperty(key, value);
    });
  }, [theme]);

  const lightTheme = () => {
    setTheme('light');
  };

  const darkTheme = () => {
    setTheme('dark');
  };

  const themeValues = {
    theme,
    lightTheme,
    darkTheme,
  };

  return (
    <ThemeContext.Provider value={themeValues}>
      {children}
    </ThemeContext.Provider>
  );
};
