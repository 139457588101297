import React, { useEffect, useState } from "react";
import xlsx from "../../Images/Extensions/xlsx.png";
import Avatar from 'react-avatar';
import TablePagination from "../pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { GetUserInvitesApi } from "../../redux/slice/organization";
import { useTheme } from '../../ThemeProvider';
import './approval.css'
import IMG from "../../Images/Extensions/xlsx.png";
import IMG1 from "../../Images/Extensions/docx.png";
import IMG2 from "../../Images/Extensions/pdf.jpg";
import IMG3 from "../../Images/Extensions/txt.png";
import IMG4 from "../../Images/Extensions/image.png";
import IMG5 from "../../Images/Extensions/powerpoint.png";
import  IMG6 from '../../Images/Extensions/doc-svgrepo-com.png'
import noExtension from "../../Images/Extensions/noExtension.png";






const UserSendedApprovalList = () => {
    const [needUserApproval,setneedUserApproval] = useState([])
  
    // const [paginatedData, setpaginatedData] = useState()
    const { theme} = useTheme();
  const dispatch = useDispatch()

  const {isLoading, Approval}= useSelector((state)=>state.organization)
  console.log("🚀 ~ UserSendedApprovalList ~ Approval:", Approval?.data)

  useEffect(()=>{
    dispatch(GetUserInvitesApi())
  },[dispatch])
  
  useEffect(()=>{
    if(Approval?.data?.length>0){
      setneedUserApproval(Approval?.data[0]?.need_user_approval)
    }
  },[Approval])

  // const paginatedlist=(val) => {
  //   setpaginatedData(val)
  //   }
    
  return (
    <>
      <div>
        <p className="fs-4 my-4 fw-semibold mx-2" style={{color:`${theme==='light'? '':'#aea8a8'}`}} > My Approval Requests</p>
      </div>
      <div className="col-12">
        <div className="row " style={{padding:"0 20px"}}>
          {
          
          isLoading ? <div className="fs-5">Loading Please wait...</div> :
          needUserApproval?.length>0 ?
          (  needUserApproval?.map((item, i) => {
            const parts = item?.docName?.split(".");
            const fileExtension = parts?.length > 1 ? "." + parts.pop() : "";
            const extensionToImageMap = {
              ".xlsx": IMG,
              ".xls": IMG,
              ".docx": IMG1,
              ".pptx": IMG5,
              ".pdf": IMG2,
              ".txt": IMG3,
              ".jpg": IMG4,
              ".png": IMG4,
              ".jpeg": IMG4,
              ".doc":IMG6,
            };

            const imageSource =
              extensionToImageMap[fileExtension] || noExtension;

            


            return (
              <div className="col-md-4 mb-3" key={i}>
                <div className="card border-0" >
                  <div className="card-body card-theme-color" >
                    <div className="d-flex justify-content-between">
                        <div className="d-flex ">
                               <Avatar 
                           color={Avatar.getRandomColor('sitebase', ['red','blue','#afacac'])} 
                           name='You'  
                           size={50}  
                           round={true}/>  
                           <div className="mx-3 d-flex align-items-center">
                            <div> 
                                <p className="m-0 fw-semibold" style={{color:`${theme==='light'? '':'#aea8a8'}`}}>You</p>
                                <small className="m-0 text-muted" style={{color:`${theme==='light'? '':'#aea8a8'}`}}>send for Approval</small>
                                </div>
                              
                           </div>
                          
                        </div>
                 
                  
                      <p className={`fw-semibold ${item.approval==='Pending'?'text-danger':'text-success'}`} onClick={()=>{window.location.reload()}}>{item.approval}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center border-0  my-3" >
                      <img
                        className="img-fluid"
                        style={{ width: "120px" }}
                        src={imageSource}
                        alt=""
                      />
                    </div>
                    <div>
                        <p className="my-2 fw-semibold general-text" style={{ color:`${theme==='light'? '':'#aea8a8'}`}}>
                          {
                          item?.documentName?.length>20 ? item?.documentName?.slice(0,20)+"..." : item?.documentName
                          }
                          </p>
                        <p className="text-muted general-text" style={{color:`${theme==='light'? '':'#aea8a8'}`}}>
                          {
                          item?.documentDescription>30 ? item?.documentDescription?.slice(0,30)+"..." : item?.documentDescription
                          }
                          </p>
                
                    </div>
                  </div>
                </div>
              </div>
            );
          }))
          :
          ( <div className="d-flex justify-content-center my-4">
          <div><p style={{color:`${theme==='light'? '':'#aea8a8'}`}}>Approval Request Not Found</p></div>
        </div>)
          
         }
        </div>
      </div>

      {/* {
      needUserApproval?.length>0 &&  <TablePagination list={needUserApproval} paginatedList={paginatedlist}/>
   
    } */}
    </>
     
  );
};

export default UserSendedApprovalList;
