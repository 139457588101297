
import {
    Box,
    IconButton,
    Typography,
    Button,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Modal from "react-modal";
  import CloseIcon from "@mui/icons-material/Close";
  import {useDispatch } from "react-redux";

  import { toast } from "react-hot-toast";
  import jwtDecode from 'jwt-decode';
import { AllUserAndOrganization, DeleteUserOROrganization, userOfOrganization } from "../../redux/slice/auth";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "500px",
      transform: "translate(-50%, -50%)",
    },
  };
  
  const DeleteUser = ({ ModalIsOpen, closeModal,ID }) => {
  
  
  const dispatch = useDispatch();
  
  
console.log(ID);
   
  
//   useEffect(()=>{
//     if(ID){
//       setdata((prev)=>({
//         ...prev,
//         folderId:ID
//       }))
//     }
//   },[ID])
    
  
  const deleteDoc = ()=>{
  dispatch(DeleteUserOROrganization(ID))
  .then(()=>{
    dispatch(AllUserAndOrganization());
    dispatch(userOfOrganization(ID))
  toast.success("user deleted successfully")
  }
  
  )
  }
  
  
  
    return (
      <Box sx={{ width: "800px" }}>
        <Modal
          isOpen={ModalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <IconButton onClick={closeModal} aria-label="delete">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
  
            <Box>
              <Typography variant="h4" 
              className="text-center"
              sx={{ fontWeight: "600", color: "#000" }}>
                Delete User
              </Typography>
  
              <Typography  
              className="text-center"
              sx={{ fontWeight: "500", color: "#000" }}>
              Are you sure to Delete this User ?
              </Typography>
              <Box
              variant='div'
              className='text-end d-flex justify-content-end mt-4'
              >
  <Button variant="contained" onClick={()=>{
  closeModal()
  deleteDoc()
  }} className="bg-danger" >Delete</Button>
              </Box>
  
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  };
  
  export default DeleteUser;
  