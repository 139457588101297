
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllQueries } from "../../redux/slice/organization";
import QueryDetail from "./QueryDetail";
import DashButton from "../Buttons/DashButton";
import { GetAllContactInfoApi } from "../../redux/slice/ContactSlice";
import ComplainModal from "./ComplainModal";
import TablePagination from "../pagination/Pagination";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Complains() {
  const [alldatalist, setalldatalist] = useState()
  const [replaceModal, setreplaceModal] = useState(false)
  const [queryData, setqueryData] = useState()
  const [data, setData] = useState()
  const{isLoading, complainData}=useSelector((state)=>state.contact)

  const dispatch = useDispatch()
  useEffect(()=>
  {
    dispatch(GetAllContactInfoApi())
  },[])
  useEffect(()=>
  {
   if(complainData){
    setalldatalist(complainData?.data)
   }
  },[complainData?.data])

  function ReplaceModalScreen(data) {
    setqueryData(data)
    setreplaceModal(!replaceModal)
  }

  const paginatedlist=(val) => {
    setData(val)
    }
  return (
    <>   
        <h3 className={`my-4 px-md-5`}  style={{fontWeight:'600'}}>Complains</h3>
      <div className=" my-1 p-md-5 m-0">
        <div className="row">
    
   
<div className="table-responsive">

    <table className="table mt-4">
            <thead>
              
              <tr>
                
                <th  className={` fw-bold fs-6 `} scope="col">
                  Name
                </th>
                <th  className={` fw-bold fs-6 `} scope="col">
                  Email
                </th>
                <th className=" fw-bold fs-6" scope="col">
                    Phone No.
                </th>
                <th className=" fw-bold fs-6" scope="col">
                    City
                </th>
                <th  className={` fw-bold fs-6 `} scope="col">
                  Message
                </th>
                <th  className={` fw-bold fs-6 `} scope="col">
                  Action
                </th>
               
              </tr>
            </thead>
            <tbody>
            {isLoading ? <p className="text-center">Loading...</p> :
            data?.map((item,i)=>{

                const messageLength= item?.message?.length>20 ? item?.message?.slice(0,20)+"..." : item?.message
                  return(
            <tr  
            style={{ backgroundColor: `white`, border: `10px solid #F8F8F8` }}
            >
                
                <td>
                
                  <p  style={{ textTransform:'capitalize'}} >{item?.name}</p>
                </td>
                <td>
                  <p  >{item?.email}</p>
                </td>
                
                    <td>
                        <p> {item?.phoneNumber}</p>
                       
                    </td>
                    <td>
                        <p> {item.city}</p>
                       
                    </td>
                    <td>
                           <p   >{messageLength}</p>
                    </td>
               
                  
                <td>
                
  {/* <DashButton
   
     label="Detail"
     color={'dark'}
  /> */}
  <InfoOutlinedIcon   onClick={()=>ReplaceModalScreen(item)} style={{color:'black',cursor: 'pointer'}} />

                </td>
              
              </tr>
                  )
                })
              }

            

           
            </tbody>
          </table>
</div>
        
        </div>
         {
        complainData?.data && <TablePagination list={complainData?.data} paginatedList={paginatedlist}/>
      }
      </div>
     


      <ComplainModal
     queryData={queryData}
       closeModal={ReplaceModalScreen}
       ModalIsOpen={replaceModal}
      />
    </>
  );
}
