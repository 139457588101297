import "./App.css";
import Login from "./components/Login/Login";
import Signup from "./components/SignUp/Signup";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";

import Contact from "./components/Contact/ContactUs"
import About from "./components/About/About"

import { Routes, Route, Outlet } from "react-router-dom";

import Protected from "./Layouts/Protected";
// import QrCode from "./components/QRCode/QrCode";
import Loading from "./components/Loading/Loading";
import UpdatePasswordBox from "./components/UpdatePasswordBox/UpdatePasswordBox";

import SideBar from "./components/dashboard1/SideBar";
import { ThemeProvider } from './ThemeProvider';
import { LandingScreen } from "./components/LandingPage.jsx/LandingScreen";
import Header from "./Layouts/Header";
import MainFooter from "./Layouts/MainFooter";
import HeaderValue from "./HeaderValue";
import Thankyou from "./components/Emailverification/Thankyou";
import RegistrationNote from "./components/Emailverification/RegistrationNote";
import XlsxFileEditor from "./components/textEditer/XlsxFileEditor";


function App() {
  return (
    <>
<ThemeProvider>
<HeaderValue>
      <Routes>
      <Route
          element={
            <>
              <Header />
              <Outlet />
              <MainFooter />
            </>
          }
        >
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/reset/:id" element={<ResetPassword />} />
          <Route path="/" element={<LandingScreen />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/update-password/:id" element={<UpdatePasswordBox />} />

        </Route>
        
        <Route element={ <Outlet />}>
           <Route path="/sidebar" element={<Protected Component={SideBar}/>} />
        </Route >



    
       <Route>
       <Route path="/thankyou" element={<Thankyou />} />
       <Route path="/registrationote" element={<RegistrationNote />} />
       <Route path='/xlsxeditor' element={<XlsxFileEditor/>}/>

       </Route>

     
      </Routes>
      <Routes>
        
      </Routes>
      </HeaderValue>
      </ThemeProvider>
    </>
  );
}

export default App;