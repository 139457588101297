import React, { useState } from "react";
import IMG from "../../../Images/Extensions/xlsx.png";
import IMG1 from "../../../Images/Extensions/docx.png";
import IMG2 from "../../../Images/Extensions/pdf.jpg";
import IMG3 from "../../../Images/Extensions/txt.png";
import IMG4 from "../../../Images/Extensions/image.png";
import IMG5 from "../../../Images/Extensions/powerpoint.png";
import IMG6 from "../../../Images/Extensions/doc-svgrepo-com.png";
import noExtension from "../../../Images/Extensions/noExtension.png";

import ShareModal from "../ShareModal";
import InviteModal from "../InviteModal";
import { useSelector, useDispatch } from "react-redux";
import { GetAllFiles } from "../../../redux/slice/DocumentsCrudSlice";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import TextEditorWord from "../../textEditer/TextEditorWord";
import FileDeleteModal from "./FileDeleteModal";
import { useTheme } from "../../../ThemeProvider";
import ViewDocModal from "./ViewDocModal";
import EditorComponent from "../../textEditer/EditorComponent";
import { ReactComponent as Deleteicon } from "../../../assets/icons/delete.svg";
import { ReactComponent as Editicon } from "../../../assets/icons/pencil.svg";
import { ReactComponent as Approvalicon } from "../../../assets/icons/approvalicon.svg";
import { ReactComponent as Viewicon } from "../../../assets/icons/view.svg";
import { ReactComponent as Shareicon } from "../../../assets/icons/shareicon.svg";
import { IconButton, Tooltip } from "@mui/material";
import XlsxEditor from "../../textEditer/XlsxEditor";

export default function FilesListTable({ folderId, searchTitle }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [viewFile, setViewfile] = useState(false);
  const [getfileData, setgetfileData] = useState();
  const [inviteModalIsOpen, setInviteIsOpen] = useState(false);
  const [allDoc, setallDoc] = useState();
  const { theme } = useTheme();

  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [xlsxmodal, setxlsxmodal] = useState(false);

  const [docId, setdocId] = useState();

  const [fileName, setfileName] = useState("");

  const [getfileDataForEdit, setgetfileDataForEdit] = useState();
  const [replaceModal, setreplaceModal] = useState(false);

  const { Documents, isLoading } = useSelector((state) => state.document);

  let user = JSON.parse(localStorage.getItem("access-token"));

  const token = user;
  const decodedToken = jwtDecode(token);
  const organizationId = decodedToken.id;

  // modales popup functions start
  function ModalScreen(item) {
    setgetfileData(item);
    setIsOpen(!modalIsOpen);
  }

  function InviteModalScreen(item) {
    setgetfileData(item);
    setInviteIsOpen(!inviteModalIsOpen);
  }
  function DeleteModalScreen() {
    setDeleteModalOpen(!DeleteModalOpen);
  }
  function ReplaceModalScreen(item) {
    setgetfileDataForEdit(item);
    setreplaceModal(!replaceModal);
    setfileName(item?.name);
  }
  function viewFileModalScreen(item) {
    console.log(item, "item");
    setgetfileData(item);
    setViewfile(!viewFile);
  }
  function xlsxEditorModal(item) {
    setgetfileDataForEdit(item);
    setxlsxmodal(!xlsxmodal);
    setfileName(item?.name);
  }
  // modales popup functions end

  //get all document api
  const dispatch = useDispatch();
  const getAllDoc = async () => {
    const response = await dispatch(
      GetAllFiles({ org_id: organizationId, doc_id: folderId })
    );
    const getValues = response.payload;
    await setallDoc(getValues);
  };

  useEffect(() => {
    getAllDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Documents.AllFilesList) {
      setallDoc(Documents.AllFilesList);
    }
  }, [Documents.AllFilesList]);

  //this is the function for search through title
  useEffect(() => {
    if (allDoc?.data?.documents && searchTitle) {
      const filteredResults = allDoc?.data?.documents.filter((item) =>
        item.name.toLowerCase().includes(searchTitle.toLowerCase())
      );

      setallDoc((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          documents: filteredResults,
        },
      }));
    } else {
      // Fetch the initial data or reset to original data when searchTitle is empty
      getAllDoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTitle]);

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th
                scope="col"
                style={{ width: "20%" }}
                className={`ps-3 fw-bold table-text`}
              >
                Type
              </th>
              <th
                scope="col"
                style={{ width: "20%" }}
                className={` fw-bold table-text`}
              >
                Name{" "}
              </th>
              <th
                scope="col"
                style={{ width: "20%" }}
                className={` fw-bold table-text`}
              >
                Status
              </th>
              <th
                scope="col"
                style={{ width: "20%" }}
                className={` fw-bold table-text`}
              >
                Modified
              </th>
              <th
                scope="col"
                style={{ width: "20%" }}
                className={` fw-bold ps-3 table-text`}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6" className="">
                  <p className={`fw-semibold fs-6 table-text `}>Loading ...</p>
                </td>
              </tr>
            ) : allDoc?.data?.documents?.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <p className={`fw-semibold fs-6 table-text`}>No File</p>
                </td>
              </tr>
            ) : (
              allDoc?.data?.documents?.map((item, i) => {
                const parts = item.name.split(".");
                const fileExtension = parts.length > 1 ? "." + parts.pop() : "";
                const extensionToImageMap = {
                  ".xlsx": IMG,
                  ".xls": IMG,
                  ".docx": IMG1,
                  ".pptx": IMG5,
                  ".pdf": IMG2,
                  ".txt": IMG3,
                  ".jpg": IMG4,
                  ".png": IMG4,
                  ".jpeg": IMG4,
                  ".doc": IMG6,
                };

                const imageSource =
                  extensionToImageMap[fileExtension] || noExtension;

                return (
                  <>
                    <tr
                      className="table-row-border table-row-background"
                      key={i}
                    >
                      <td className="">
                        <div className="">
                          <img
                            src={imageSource}
                            style={{ width: "50px", height: "50px" }}
                            alt=""
                          />
                        </div>
                      </td>

                      <td className="">
                        <div className="">
                          <p
                            className={`table-text`}
                            style={{
                              fontWeight: 700,
                              marginTop: 10,
                              margim: 10,
                            }}
                          >
                            {item?.name?.length > 25
                              ? `${item?.name?.slice(0, 25)}...`
                              : item?.name}
                            <small
                              className="text-muted"
                              style={{ fontSize: 10 }}
                            ></small>
                          </p>
                        </div>
                      </td>

                      <td className="">
                        <div className="  pt-2">
                          <p className={` d-flex table-text`}>select</p>
                        </div>
                      </td>

                      <td className="">
                        <div className="  pt-2">
                          <p className={` d-flex table-text`}>
                            {item?.description?.length > 30
                              ? `${item?.description?.slice(0, 30)}...`
                              : item?.description}
                          </p>
                        </div>
                      </td>

                      <td className="">
                        <div className="icon-container mt-2">
                          <div className="icon">
                            <Tooltip title="View Document ">
                              <IconButton className="p-0 ">
                                <Viewicon
                                  className={`table-text`}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                    fill: `${
                                      theme === "light" ? "black" : "white"
                                    }`,
                                  }}
                                  onClick={() => viewFileModalScreen(item)}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="icon">
                            <Tooltip title="Send Approval">
                              <IconButton className="p-0 ">
                                <Approvalicon
                                  className={`table-text`}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                    stroke: `${
                                      theme === "light" ? "black" : "white"
                                    }`,
                                  }}
                                  onClick={() => ModalScreen(item)}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="icon">
                            {fileExtension &&
                              (() => {
                                switch (fileExtension) {
                                  case ".xlsx":
                                  case ".xls":
                                  case ".csv":
                                    return (
                                      <Tooltip title="Edit Document">
                                        <IconButton className="p-0">
                                          <Editicon
                                            style={{
                                              cursor: "pointer",
                                              marginTop: 5,
                                              fill: ` ${
                                                theme === "light"
                                                  ? "black"
                                                  : "white"
                                              }`,
                                            }}
                                            onClick={() => {
                                              ReplaceModalScreen(item);
                                              // xlsxEditorModal(item)
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    );
                                  case ".docx":
                                  case ".pptx":
                                  case ".doc":
                                    return (
                                      <Tooltip title="Edit Document">
                                        <IconButton className="p-0">
                                          <Editicon
                                            style={{
                                              cursor: "pointer",
                                              marginTop: 5,
                                              fill: ` ${
                                                theme === "light"
                                                  ? "black"
                                                  : "white"
                                              }`,
                                            }}
                                            onClick={() => {
                                              ReplaceModalScreen(item);
                                              // xlsxEditorModal(item)
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    );
                                  // case '.pdf':
                                  //   return <PDFReader fileUrl={fileData?.doc} />;
                                  default:
                                    return (
                                      <Tooltip title="Editor Not available">
                                        <IconButton className="p-0">
                                          <Editicon
                                            style={{
                                              cursor: "pointer",
                                              marginTop: 5,
                                              fill: ` ${
                                                theme === "light"
                                                  ? "black"
                                                  : "white"
                                              }`,
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    );
                                }
                              })()}
                          </div>
                          <div className="vertical-line"></div>
                          <div className="icon">
                            <Tooltip title="Share Document ">
                              <IconButton className="p-0 ">
                                <Shareicon
                                  className={`table-text`}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                    stroke: `${
                                      theme === "light" ? "black" : "white"
                                    }`,
                                  }}
                                  onClick={() => InviteModalScreen(item)}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="icon">
                            <Tooltip title="Delete Document ">
                              <IconButton className="p-0">
                                <Deleteicon
                                  className={``}
                                  onClick={() => {
                                    DeleteModalScreen();
                                    setdocId(item.id);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                    fill: `${
                                      theme === "light" ? "black" : "white"
                                    }`,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <TextEditorWord docId={docId} />

      {/* Open modal */}
      <ShareModal
        closeModal={ModalScreen}
        fileData={getfileData}
        modalIsOpen={modalIsOpen}
      />
      <InviteModal
        fileData={getfileData}
        closeInviteModal={InviteModalScreen}
        inviteModalIsOpen={inviteModalIsOpen}
      />
      <FileDeleteModal
        ID={docId}
        folderId={folderId}
        closeModal={DeleteModalScreen}
        ModalIsOpen={DeleteModalOpen}
      />
      <EditorComponent
        fileData={getfileDataForEdit}
        folderId={folderId}
        closeEditor={ReplaceModalScreen}
        EditorIsOpen={replaceModal}
        fileName={fileName}
      />
      <XlsxEditor
        fileData={getfileDataForEdit}
        folderId={folderId}
        closeEditor={xlsxEditorModal}
        EditorIsOpen={xlsxmodal}
        fileName={fileName}
      />

      <ViewDocModal
        fileData={getfileData}
        closeModal={viewFileModalScreen}
        ModalIsOpen={viewFile}
      />
      {/* Open modal */}
    </>
  );
}
