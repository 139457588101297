
import {
    Box,
    IconButton,
    Typography,
    Button,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Modal from "react-modal";
  import CloseIcon from "@mui/icons-material/Close";
  import {useDispatch } from "react-redux";
  import { toast } from "react-hot-toast";
  import { useTheme } from '../../ThemeProvider';
  import DashButton from "../Buttons/DashButton";
import { baseURL } from "../../axios/axios";
import axios from "axios";
import { GetOrgUserApi } from "../../redux/slice/organization";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "400px",
      transform: "translate(-50%, -50%)",
    },
  };
  
  const DeleteUserModal= ({ ModalIsOpen, closeModal,userId }) => {
  console.log("🚀 ~ DeleteUserModal ~ userId:", userId)
  
    const { theme} = useTheme();
  
  const dispatch = useDispatch();
 
  


  
  
  const handleDeleteUser = () => {
    axios
      .delete(`${baseURL}api/user/organization/${userId}`, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access-token")
          )}`,
        },
      })
      .then((res) => {
        if (res.data.message === "Success") {
          toast.success("Successfully Deleted the user");
          dispatch(GetOrgUserApi())
        } else {
          toast.error("User not deleted please try again!");
        }
      });
  };
  
  
  
    return (
      <Box sx={{ width: "800px" }}>
        <Modal
          isOpen={ModalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <IconButton onClick={closeModal} aria-label="delete">
                <CloseIcon sx={{  color: `${theme==='light'? '#000':'white'}` }} />
              </IconButton>
            </Box>
  
            <Box>
              <Typography variant="h4" 
              className="text-center"
              sx={{ fontWeight: "600",  color: `${theme==='light'? '#000':'white'}` }}>
                Delete User
              </Typography>
  
              <Typography  
              className="text-center mt-3"
              sx={{ fontWeight: "300",  color: `${theme==='light'? '#000':'white'}` }}>
              Are you sure to Delete this User ?
              </Typography>
              <Box
              variant='div'
              className='text-end mt-4'
              >
  {/* <Button variant="contained" >Delete</Button> */}
  
    <DashButton
     onClick={()=>{
  closeModal()
  handleDeleteUser()
  
  }}
  label="Delete"
  color={'dark'}
  
    
    />
  
              </Box>
  
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  };
  
  export default DeleteUserModal;
  