import React, { useState } from "react";
import IMG from "../../../Images/Extensions/xlsx.png";
import IMG1 from "../../../Images/Extensions/docx.png";
import IMG2 from "../../../Images/Extensions/pdf.jpg";
import IMG3 from "../../../Images/Extensions/txt.png";
import IMG4 from "../../../Images/Extensions/image.png";
import IMG5 from "../../../Images/Extensions/powerpoint.png";
import  IMG6 from '../../../Images/Extensions/doc-svgrepo-com.png'
import noExtension from "../../../Images/Extensions/noExtension.png";

import ShareModal from "../ShareModal";
import InviteModal from "../InviteModal";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllFiles,
} from "../../../redux/slice/DocumentsCrudSlice";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import TextEditorWord from "../../textEditer/TextEditorWord";
import FileDeleteModal from "./FileDeleteModal";
import { useTheme } from "../../../ThemeProvider";
import ViewDocModal from "./ViewDocModal";
import EditorComponent from "../../textEditer/EditorComponent";
import { ReactComponent as Deleteicon } from "../../../assets/icons/delete.svg";
import { ReactComponent as Editicon } from "../../../assets/icons/pencil.svg";
import { ReactComponent as Approvalicon } from "../../../assets/icons/approvalicon.svg";
import { ReactComponent as Viewicon } from "../../../assets/icons/view.svg";
import { ReactComponent as Shareicon } from "../../../assets/icons/shareicon.svg";
import { IconButton, Tooltip } from "@mui/material";
import XlsxEditor from "../../textEditer/XlsxEditor";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function FilesListTable({ folderId, searchTitle }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [viewFile, setViewfile] = useState(false);
  const [getfileData, setgetfileData] = useState();
  const [inviteModalIsOpen, setInviteIsOpen] = useState(false);
  const [allDoc, setallDoc] = useState();
  const { theme } = useTheme();
  
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [xlsxmodal, setxlsxmodal] = useState(false);

  const [docId, setdocId] = useState();

  const [fileName, setfileName] = useState("");

  const [getfileDataForEdit, setgetfileDataForEdit] = useState();
  const [replaceModal, setreplaceModal] = useState(false);

  const { Documents, isLoading } = useSelector((state) => state.document);

  let user = JSON.parse(localStorage.getItem("access-token"));

  const token = user;
  const decodedToken = jwtDecode(token);
  const organizationId = decodedToken.id;

  // modales popup functions start
  function ModalScreen(item) {
    setgetfileData(item);
    setIsOpen(!modalIsOpen);
  }

  function InviteModalScreen(item) {
    setgetfileData(item);
    setInviteIsOpen(!inviteModalIsOpen);
  }
  function DeleteModalScreen() {
    setDeleteModalOpen(!DeleteModalOpen);
  }
  function ReplaceModalScreen(item) {
    setgetfileDataForEdit(item);
    setreplaceModal(!replaceModal);
    setfileName(item?.name);
  }
  function viewFileModalScreen(item) {
    setgetfileData(item);
    setViewfile(!viewFile);
  }
  function xlsxEditorModal(item){
    setgetfileDataForEdit(item);
    setxlsxmodal(!xlsxmodal);
    setfileName(item?.name);
  }
  // modales popup functions end

  //get all document api
  const dispatch = useDispatch();
  const getAllDoc = async () => {
    const response = await dispatch(
      GetAllFiles({ org_id: organizationId, doc_id: folderId })
    );
    const getValues = response.payload;
    await setallDoc(getValues);
  };

  useEffect(() => {
    getAllDoc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Documents.AllFilesList) {
      setallDoc(Documents.AllFilesList);
    }
  }, [Documents.AllFilesList]);

  //this is the function for search through title
  useEffect(() => {
    if (allDoc?.data?.documents && searchTitle) {
      const filteredResults = allDoc?.data?.documents.filter((item) =>
        item.name.toLowerCase().includes(searchTitle.toLowerCase())
      );

      setallDoc((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          documents: filteredResults,
        },
      }));
    } else {
      // Fetch the initial data or reset to original data when searchTitle is empty
      getAllDoc();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTitle]);


  return (
    <>
      <div className='row p-0 mb-5 mx-3'>
        {  isLoading ?  <p className={`fw-semibold fs-6 table-text `}>Loading ...</p> :
        allDoc?.data?.documents?.length === 0 ?  <p className={`fw-semibold fs-6 table-text`}>No File</p> :
            allDoc?.data?.documents?.map((item,i)=>{
                const formattedDate = new Date(item?.updatedAt).toLocaleDateString('en', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  });

                  const parts = item.name.split(".");
                  const fileExtension = parts.length > 1 ? "." + parts.pop() : "";
                  const extensionToImageMap = {
                    ".xlsx": IMG,
                    ".xls": IMG,
                    ".docx": IMG1,
                    ".pptx": IMG5,
                    ".pdf": IMG2,
                    ".txt": IMG3,
                    ".jpg": IMG4,
                    ".png": IMG4,
                    ".jpeg": IMG4,
                    ".doc":IMG6,
                  };
  
                  const imageSource =
                    extensionToImageMap[fileExtension] || noExtension;
                  const name = item?.name?.length>11 ? `${item?.name?.slice(0,11)}...`: item?.name
                return (
                    <div className="col-md-3 col-sm-1 mt-2" key={i}  style={{cursor:'pointer'}}  >
                        <div  className={`card border-0  bg-white mx-3 shadow-sm ${theme==='light'? '':'theme-back'}`}  >
            <div className='d-flex justify-content-end my-2'>
   
<div class="dropdown">
 <MoreVertIcon style={{cursor:'pointer'}} 
            data-bs-toggle="dropdown" aria-expanded="false"
            className={`dropdown-toggle ${theme==='light'? 'pic-ver fs-2':'text-white fs-2'}`} 
      />
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#"  
            onClick={() => {
              DeleteModalScreen();
              setdocId(item.id);
            }} >Delete</a></li>
    
  </ul>
</div>
                
           
            </div>
            <div className='d-flex justify-content-center'>


            <div className=""  onClick={() => viewFileModalScreen(item)}>
                      <img
                            src={imageSource}
                            style={{ width: "90px" }}
                            alt=""
                          />
        </div>
                     
      
            </div>
            <div  className='d-flex justify-content-center mt-md-3' 
             >
            <h4 style={{color:'#43425D',fontSize:'18px',fontFamily:'GoogleSans,Sans-serif',fontWeight:'600',textTransform:'capitalize',cursor:'pointer'}}
            className={` ${theme==='light'? '':'text-white'}`} 
           
            >
               {name}
            </h4>
            </div>
             <div  className='d-flex justify-content-center'>
           <p  style={{fontSize:'11px',opacity:'1px'}} className={` ${theme==='light'? 'text-muted':'text-white'}`} >
                Modified {formattedDate}
            </p>
             </div>
           
        </div>    
                    </div>
                 
                )
            })
        }
       

    
    </div>

      <TextEditorWord docId={docId} />

      {/* Open modal */}
      <ShareModal
        closeModal={ModalScreen}
        fileData={getfileData}
        modalIsOpen={modalIsOpen}
      />
      <InviteModal
        fileData={getfileData}
        closeInviteModal={InviteModalScreen}
        inviteModalIsOpen={inviteModalIsOpen}
      />
      <FileDeleteModal
        ID={docId}
        folderId={folderId}
        closeModal={DeleteModalScreen}
        ModalIsOpen={DeleteModalOpen}
      />
      <EditorComponent
        fileData={getfileDataForEdit}
        folderId={folderId}
        closeEditor={ReplaceModalScreen}
        EditorIsOpen={replaceModal}
        fileName={fileName}
      />
      <XlsxEditor 
      
      fileData={getfileDataForEdit}
      folderId={folderId}
      closeEditor={xlsxEditorModal}
       EditorIsOpen={xlsxmodal}
       fileName={fileName}
       />

      <ViewDocModal
        fileData={getfileData}
        closeModal={viewFileModalScreen}
        ModalIsOpen={viewFile}
      />
      {/* Open modal */}
    </>
  );
}
