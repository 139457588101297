
import {
  Box,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch } from "react-redux";
import { DeleteFolder, GetAllFolder, deleteDocument, getAllDocuments } from "../../../redux/slice/DocumentsCrudSlice";
import { toast } from "react-hot-toast";
import jwtDecode from 'jwt-decode';
import { useTheme } from '../../../ThemeProvider';
import DashButton from "../../Buttons/DashButton";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "900px",
    transform: "translate(-50%, -50%)",
  },
};

const DeleteModal = ({ ModalIsOpen, closeModal,ID }) => {

  const { theme} = useTheme();

const dispatch = useDispatch();
  const [personName, setPersonName] = useState([]);
  let user = JSON.parse(
  localStorage.getItem("access-token")
)


const token = user;
const decodedToken = jwtDecode(token);
const organizationId = decodedToken.id
console.log(ID, 'delete able doc');
  const [data, setdata] = useState({
    organizationId:organizationId,
    folderId:'',
  })
 

useEffect(()=>{
  if(ID){
    setdata((prev)=>({
      ...prev,
      folderId:ID
    }))
  }
},[ID])
  

const deleteDoc = ()=>{
dispatch(DeleteFolder(data))
.then(()=>{
  dispatch(GetAllFolder(organizationId));
toast.success("document deleted successfully")
}

)
}



  return (
    <Box sx={{ width: "800px" }}>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <IconButton onClick={closeModal} aria-label="delete">
              <CloseIcon sx={{  color: `${theme==='light'? '#000':'white'}` }} />
            </IconButton>
          </Box>

          <Box>
            <Typography variant="h4" 
            className="text-center"
            sx={{ fontWeight: "600",  color: `${theme==='light'? '#000':'white'}` }}>
              Delete Document
            </Typography>

            <Typography  
            className="text-center mt-3"
            sx={{ fontWeight: "300",  color: `${theme==='light'? '#000':'white'}` }}>
            Are you sure to Delete this Document ?
            </Typography>
            <Box
            variant='div'
            className='text-end'
            >
{/* <Button variant="contained" >Delete</Button> */}

  <DashButton
   onClick={()=>{
closeModal()
deleteDoc()

}}
label="Delete"
color={'dark'}

  
  />

            </Box>

          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DeleteModal;
