import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonWrapper,
  CancelButton,
  IconButtonStyle,
  InputLabelText,
  PasswordBoxWrapper,
  Title,
  UpdateButton,
  UpdatePasswordPageWrapper,
} from "./updatePassword.style";
import "./updatePasswordBox.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { EmailVerification, updateOneTimePassword } from "../../redux/slice/organization";
import { toast } from "react-hot-toast";

const UpdatePasswordBox = () => {
  const [password, setShowPassword] = useState(false);
  const [PasswordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [confirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setdata] = useState({
    token:'',
    data:{
      password:'',
      passwordConfirm:'',
    },
  })

console.log(data,'payload data');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // redux action dispatch
  const dispatch = useDispatch();

const navigate = useNavigate();
  const { id } = useParams();

  console.log(id);


const getToken =async()=>{
  const reponse = await dispatch(EmailVerification(id));
  const value = reponse.payload?.data?.token
  
setdata((prevData)=>({...prevData,token:value}))
}

  

  useEffect(() => {
 
    getToken()
 
  }, []);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPasswordValue(newPassword);
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        password: newPassword,
      },
    }));
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPasswordValue(newConfirmPassword);
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        passwordConfirm: newConfirmPassword,
      },
    }));
  };

  const onSubmit = () => {
 
    //   });
    if(PasswordValue===confirmPasswordValue){
    
        dispatch(updateOneTimePassword(data))
        .then((response)=>{
          if(response.payload.message==="success"){
             toast.success("password update successfully!. please login with your account")
             navigate('/')
          }
         
          


        })
    }else{
      toast.error("password and confirm Password is not same")
    }
  
  };

  return (
    <UpdatePasswordPageWrapper>
      <PasswordBoxWrapper>
        <Box sx={{ mb: { xs: "20px", lg: "50px" } }}>
          <Title>Update Password</Title>
        </Box>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              mb: {
                xs: "10px",
                lg: "30px",
              },
            }}
          >
            <InputLabelText>Password</InputLabelText>

            <FormControl sx={{ width: "100%", position: "relative" }}>
              <Box>
                <input
                  placeholder="******"
                  type={password ? "text" : "password"}
                  className="input"
                  
                  {...register("password", {
                    required: "Password is required.",
                  })}
                  value={PasswordValue}
                  onChange={handlePasswordChange}
                />
                {errors.password && (
                  <div className="text-danger mb-2">
                    {errors.password.message}
                  </div>
                )}
              </Box>
              <IconButtonStyle
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => setShowPassword(!password)}
              >
                {password ? <VisibilityOff /> : <Visibility />}
              </IconButtonStyle>
            </FormControl>
          </Box>

          <Box>
            <InputLabelText>Confirm Password</InputLabelText>

            <FormControl sx={{ width: "100%", position: "relative" }}>
              <Box>
                <input
                  placeholder="******"
                  type={password ? "text" : "password"}
                  className="input"
                  {...register("passwordConfirm", {
                    required: "Confirm Password is required.",
                  })}
                  value={confirmPasswordValue}
                  onChange={handleConfirmPasswordChange}
                />
                {errors.passwordConfirm && (
                  <div className="text-danger mb-2">
                    {errors.passwordConfirm.message}
                  </div>
                )}
              </Box>
              <IconButtonStyle
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => setShowConfirmPassword(!confirmPassword)}
              >
                {confirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButtonStyle>
            </FormControl>
          </Box>

          <ButtonWrapper>
            <CancelButton variant="text">Cancel</CancelButton>
            <UpdateButton variant="text" type="submit">
              Update
            </UpdateButton>
          </ButtonWrapper>
        </Box>
      </PasswordBoxWrapper>
    </UpdatePasswordPageWrapper>
  );
};

export default UpdatePasswordBox;
