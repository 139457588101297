import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Switch, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import image from "../../Images/Vector.png";
import { useDispatch, useSelector } from "react-redux";
import { AllUserAndOrganization, blockUser } from "../../redux/slice/auth";
import DeleteUser from "./DeleteUser";
import OrganizationUsers from "./OrganizationUsers";
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Userlisticon } from '../../assets/icons/userList.svg';
import { ReactComponent as Deleteicon } from '../../assets/icons/delete.svg';
import toast from "react-hot-toast";



const OrganizationsScreen = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [allUser, setallUser] = useState();
  console.log("🚀 ~ file: OrganizationsScreen.jsx:19 ~ OrganizationsScreen ~ allUser:", allUser)
  const [docId, setdocId] = useState();
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [CreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  // const [openModalCreateUser, setopenModalCreateUser] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  const getUpdatedValues = useSelector((state) => state.user.userData.UserAndOrganizationList);
  const {isLoading} = useSelector((state) => state.user);



  function UserListModalScreen() {
    setCreateUserModalOpen(!CreateUserModalOpen);
  }

  function DeleteModalScreen() {
    setDeleteModalOpen(!DeleteModalOpen);
  }
  // function CreateUserModalScreen (){
  //   setopenModalCreateUser(!openModalCreateUser)


  //   }

  useEffect(() => {
    const getData = async () => {
      await dispatch(AllUserAndOrganization());
    };
    getData();
  }, []);

  useEffect(() => {
    if (getUpdatedValues) {
      const filteredResults = getUpdatedValues?.data?.result.filter((item) => item.role === "organization");
      console.log("🚀 ~ file:************* filteredResults:", filteredResults)

      setallUser(filteredResults);
    }
  }, [getUpdatedValues?.data?.result]);


  const toggleUserStatus = async (User) => {
    const userIndex = allUser.findIndex((item) => item._id === User._id);
    const updatedUsers = [...allUser];

    const isActive = User.status === "active";
    const newStatus = isActive ? "false" : "active";

    updatedUsers[userIndex] = { ...User, status: newStatus };
    setallUser(updatedUsers);
    ;
   const res = await dispatch(blockUser({ status: newStatus, userId: User._id }))
   if(res.payload.message){
    toast.success("Status Updated Successfully!")
   }



  };
  const filteredUsers = allUser?.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <div className="px-md-5">
        <div className=" my-4">

          <h3 className={`dark-text`} style={{ marginBottom: '80px', fontWeight: '600' }}>Organization List</h3>
        </div>
        <div className="row">
          <div className="col-md-3">

          <div className="d-flex align-items-center search-include">
              <SearchIcon className='mx-2 search-icon-color' />
              <input
                className="folder-search"
                type="search"
                placeholder="Search Item..."
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>



        <div className="table-responsive">

          <table className="table mt-4">
            <thead>

              <tr>

                <th className={`ps-3 fw-bold fs-6 `} scope="col">
                  Name
                </th>
                <th className={` fw-bold fs-6 `} scope="col">
                  Status
                </th>
                <th className=" fw-bold fs-6" scope="col">
                  Phone.No
                </th>
                <th className=" fw-bold fs-6" scope="col">
                  Email
                </th>
                <th className={` fw-bold fs-6 `} scope="col">
                  Change Status
                </th>
                <th className={` fw-bold fs-6 `} scope="col">
                  Action
                </th>

              </tr>
            </thead>
            <tbody>
              { isLoading ? <h2 className="p-4">Loading...</h2> :
              filteredUsers?.length === 0 ? <div className="d-flex w-100 justify-content-center p-5"><h2>No Data</h2></div>  :
              filteredUsers?.map((data, i) => (
                <tr key={i}
                  style={{ backgroundColor: `white`, border: `10px solid #F8F8F8` }}
                >
                  <th scope="row">
                    <div className="d-flex">
                      <img src={image} alt="" />
                      <p className="ms-2 pt-2">{data.name}</p>
                    </div>
                  </th>
                  <td className="pt-3">

                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: `${data.status === 'active' ? '#2EBD92' : data.status === 'false' ? "#dd4747" : "#bcbc1b"}`,
                        color: "#fff",
                        width: '120px',
                        letterSpacing: '1.3px',
                        textTransform: "upperCase",
                        fontSize: "12px",
                        fontWeight: "700",
                        borderRadius: "8px",
                        // "&:hover": {
                        //   backgroundColor: "#2EBD59",
                        // },
                      }}
                      disabled
                    >
                      {data.status === 'pending' ? 'Invited' : data.status === 'active' ? 'Active' : 'De Active'}
                    </Button>

                  </td>

                  <td className="pt-3">
                    <span>
                      {data.phNo ? data.phNo?.slice(0, 15) : 'NIL'}
                      {/* <br />{" "}
                          <span className="text-secondary">Contact No</span> */}
                    </span>
                  </td>
                  <td className="pt-3">
                    <span >
                      {data.email ? data.email : 'NIL'}
                      {/* <br /> <span className="text-secondary">Email</span> */}
                    </span>
                  </td>
                  <td className="pt-2">

                    <Switch
                      disabled={data.status === 'pending'}
                      sx={{
                        "& .MuiSwitch-colorPrimary": {
                          color: "#28C76F",
                        },
                      }}
                      checked={data.status === "active" && data.status !== "false"}
                      onChange={() => toggleUserStatus(data)}
                    />


                  </td>

                  <td>
                    <div className="icon-container py-2">
                      <div className="icon">
                        <Userlisticon
                          onClick={() => {
                            UserListModalScreen();
                            setdocId(data._id);
                          }}
                          style={{ cursor: 'pointer', fill: `black`, stroke: `black` }}
                        />
                      </div>
                      <div className="vertical-line"></div>
                      <div className="icon">  <Deleteicon
                        onClick={() => {
                          DeleteModalScreen();
                          setdocId(data._id);
                        }}
                        style={{ cursor: 'pointer', fill: `black` }}
                      /></div>

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <DeleteUser
            ID={docId}
            closeModal={DeleteModalScreen}
            ModalIsOpen={DeleteModalOpen}
          />{" "}
          <OrganizationUsers
            org_id={docId}
            closeModal={UserListModalScreen}
            ModalIsOpen={CreateUserModalOpen}
          />
        </div>
      </div>

    </>



  );
};

export default OrganizationsScreen;
