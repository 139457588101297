import React, { useEffect, useState } from "react";
import { useTheme } from '../../ThemeProvider';
import { useDispatch, useSelector } from "react-redux";
import { GetAllQueries } from "../../redux/slice/organization";
import QueryDetail from "./QueryDetail";
import DashButton from "../Buttons/DashButton";
import DashTable from "../tables/DashTable";
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TablePagination from "../pagination/Pagination";

export default function QuerScreen() {
  const { theme} = useTheme();
  const [alldatalist, setalldatalist] = useState();
  const [replaceModal, setreplaceModal] = useState(false);
  const [queryData, setqueryData] = useState();
  const [paginatedData, setpaginatedData] = useState();
  const [searchTerm, setSearchTerm] = useState(''); // State to store search term
  const {isLoading, AllQueries} = useSelector((state) => state.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllQueries());
  }, []);

  useEffect(() => {
    if (AllQueries) {
      setalldatalist(AllQueries?.data);
    }
  }, []);

  function ReplaceModalScreen(data) {
    setqueryData(data);
    setreplaceModal(!replaceModal);
  }

  const paginatedlist = (val) => {
    setalldatalist(val);
  }

  // Filter data based on search term
  const filteredData = alldatalist?.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className=" my-1 px-md-5 m-0">
        <div className="row my-3">
          <h3 className={`bdms-title table-text`} style={{marginBottom:"80px"}}>Queries</h3>
          <div className="row " style={{marginLeft:"0px"}}>
            <div className="col-md-3">
              <div className="d-flex align-items-center search-include">
                <SearchIcon className='mx-2 search-icon-color' />
                <input
                  className=" folder-search"
                  type="search"
                  placeholder={`Search Item...`}
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive mt-5">
            <table className="table">
              <thead>
                <tr>
                  <th  className={`ps-4 fw-bold table-text`} scope="col">Name</th>
                  <th  className={`fw-bold table-text`} scope="col">Email</th>
                  <th  className={`fw-bold table-text`} scope="col">Message</th>
                  <th  className={`fw-bold table-text`} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                filteredData?.length === 0 || !filteredData ? ( <tr>
                  <td colSpan="6" className="text-center">
                  <p className={`fw-semibold fs-6 table-text mt-3`}  >No Queries</p>
                  </td>
                  </tr>) :
                filteredData?.map((item, i) => (
                  <tr key={i} className="table-row-border table-row-background">
                    <td>
                      <p className={`ps-2 table-text`} style={{marginTop: 10, margim: 10 }}>
                        {item?.name?.length > 15 ? `${item?.name?.slice(0, 15)}......` : item?.name}
                      </p>
                    </td>
                    <td>
                      <p className={`table-text`} style={{marginTop: 10, margim: 10 }}>
                        {item.email}
                      </p>
                    </td>
                    <td>
                      <p className={`table-text`} style={{marginTop: 10, margim: 10 }}>
                        {item?.message?.length > 30 ? `${item?.message?.slice(0, 30)} .......` : item?.message}
                      </p>
                    </td>
                    <td>
                      <div className="d-flex align-items-center mt-2">
                        <InfoOutlinedIcon className={`table-text`} style={{cursor:'pointer'}} onClick={() => ReplaceModalScreen(item)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>    
            <div className="ms-2">
              {AllQueries?.data && <TablePagination list={AllQueries?.data} paginatedList={paginatedlist} />} 
            </div>
          </div>
        </div>
      </div>
      <QueryDetail
        queryData={queryData}
        closeModal={ReplaceModalScreen}
        ModalIsOpen={replaceModal}
      />
    </>
  );
}
