
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SendQueryApi } from '../../redux/slice/organization';
import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';
import DashButton from '../Buttons/DashButton';

export default function SendQueryByUser() {


const dispatch = useDispatch()

let user = JSON.parse(localStorage.getItem("access-token"));
const {isLoading}=useSelector((state)=>state.organization)
const token = user;
const decodedToken = jwtDecode(token);
const userId = decodedToken.id;

  const { register, 
    handleSubmit, 
    reset,
    formState: { errors } } = useForm();

  const sendQuery = async(data) => {
    console.log(data);
await dispatch(SendQueryApi({...data,user:userId}))
.then((res)=>{

toast.success("Query sended successfully")
reset()
})
    
}
  console.log(errors);
  
  return (
    <>
    <div className="query-title mt-3 mx-5">
      <h2 style={{fontWeight:'600'}}> Query</h2>
     
    </div>
    <div className="row justify-content-center">
      <div className="col-md-6">
    <div className="py-md-5" style={{marginTop:'50px'}}>
   <div className=''>
        {/* <p className='h3 my-5'>Query</p> */}
    </div>
    <div className="container">
         <form onSubmit={handleSubmit(sendQuery)}>
        
          <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Your Name</label>
    <input type="text"
     name='name' 
     class="bdms-profile-input" 
     id="exampleInputEmail1" 
     aria-describedby="emailHelp"
     placeholder='Name here'
     {...register("name", {required: "this is requred", maxLength: 80})}
    />
      {errors.name && errors.name.type === "required" && (
                <div className="text-danger mt-1"><small>name is required</small></div>
              )}
   
  </div>

       
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Email address</label>
    <input type="email" 
    name='email' 
    class="bdms-profile-input" 
    id="exampleInputEmail1" 
    aria-describedby="emailHelp"
    placeholder='info@companyname.com'
    {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
    />
      {errors.email && errors.email.type === "required" && (
                <div className="text-danger mt-2">email is required</div>
              )}
    
  </div>


  <div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Query Detail</label>
  <textarea class="bdms-profile-input"
   name='message' id="exampleFormControlTextarea1"
    rows="8"
    placeholder='Type here...'
   {...register("message", {required: true, minLength: 1, maxLength: 400})}
  ></textarea>
    {errors.message && errors.message.type === "required" && (
                <div className="text-danger mt-2">Qurey Detail is required</div>
              )}
</div>
     
   


<DashButton
 type='submit'
 color='dark'
label='Submit'
disabled={isLoading}
/>
    </form>
    </div>
</div>    
      </div>
    </div>

   
     
    </>

    
  
  );
}