import {useCallback, useEffect, useRef, useState} from 'react';
import WebViewer from '@pdftron/webviewer'
import { useDispatch } from 'react-redux';
import { GetAllFiles, viewSingleDocuments } from '../../redux/slice/DocumentsCrudSlice';
import jwtDecode from 'jwt-decode';
import { baseURL } from '../../axios/axios';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import upgrade from '../../Images/Extensions/upgrade.png';

import {
  Box,
  IconButton,

} from "@mui/material";

import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '../../ThemeProvider';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    height:"80%",

    transform: "translate(-50%, -50%)",
  },
};


const EditorComponent = ({fileData,folderId,closeEditor, EditorIsOpen,fileName,data}) => {
console.log("🚀  EditorComponent  fileData:", fileData)

const { theme} = useTheme();

  const dispatch = useDispatch()
  const [fileUrl, setfileUrl] = useState('')
  const [Loading, setLoading] = useState(false);

      let modifiedString = '';
  let user = JSON.parse(
    localStorage.getItem("access-token")
  )

  const token = user;
  const decodedToken = jwtDecode(token);
  const org_id = decodedToken.organization
  const user_id = decodedToken.id
 

  const viewer = useRef(null);
  
  useEffect(()=>{
    if(fileData){
      if(data?.length > 0){
        dispatch(viewSingleDocuments({org_id,doc_id:fileData?.documentId?._id})) 
        return
      }

      else {
     dispatch(viewSingleDocuments({org_id,doc_id:fileData?._id}))
        .then((res)=>{
          const path = res?.payload?.data?.link;  
         modifiedString= path?.replace(/^.*[\\\/]/, '');//this link will extract file name from array
          setfileUrl(modifiedString)
          // runEditorFunction(modifiedString)
// setfileUrl(modifiedString)
     
        })
     } 
    }
   },[fileData])


   WebViewer(
    { 
      path: '/webviewer/lib',
      licenseKey: 'demo:1701171675759:7caaa1660300000000bd5359260e5438426547dfd116d2177479a7e154',
      initialDoc: data?.length > 0 ? fileData?.documentId?.doc : fileData?.doc,
      enableOfficeEditing: true,
    }, 
  
    viewer.current
  ).then((instance) => {
    const { documentViewer } = instance.Core;
    instance.UI.setHeaderItems((header) => {
      header.push({
        type: 'actionButton',
        dataElement: 'moreButton',
         img: upgrade,
        title: 'upgrade',
        onClick: async () => {
          const doc = await documentViewer.getDocument().getFileData();
          uploadDocument(doc);
        },
      });
    });
  });

  







   const uploadDocument = async (doc) => {
    console.log("🚀  uploadDocument  doc:", doc)
    try {
      // Create FormData
      const formData = new FormData();
      formData.append('doc', new Blob([doc], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }), 'edited_document.docx');

      // Extract file name from the doc object and append it
       // Use a default name if not available
       if(data?.length > 0){
        formData.append('name', fileData?.documentId?.name);
        formData.append("folderId", fileData?.documentId?.folderId);
        formData.append("description", fileData?.documentId?.description);
       }
       else {
          formData.append('name', fileData?.name);
      formData.append("folderId", folderId);
      formData.append("description", fileData?.description);
       }
    
      formData.append("contentType", "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  
      // Replace 'yourUploadAPI' with the actual endpoint to upload the document
     if(data?.length > 0){
      console.log("first",fileData?.documentId?._id)
       await axios.post(`${baseURL}api/workDocs/document/${user_id}/${fileData?.documentId?._id}`, formData, {
        headers: {
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access-token")
          )}`,
        },
      })
      .then(async () => {
        toast.success("file updated successfully");
        await dispatch(GetAllFiles({ org_id: user_id, doc_id: fileData?.documentId?.folderId }));
        closeEditor()
        window.location.reload()
      })
     }

     else {
      console.log('second')
      await axios.post(`${baseURL}api/workDocs/document/${user_id}/${fileData?._id}`, formData, {
        headers: {
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access-token")
          )}`,
        },
      })
      .then(async (res) => {
        toast.success("file updated successfully");
        await dispatch(GetAllFiles({ org_id: user_id, doc_id: folderId }));
        closeEditor()
      })
     }
     

    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };












  
  return (
   <>

<Box>
        <Modal
          isOpen={EditorIsOpen}
          onRequestClose={closeEditor}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "20px",height:'100%' }}>
            <Box
            
              sx={{
            
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                // height:'100%'
              }}
            >
              <IconButton onClick={()=>{
                closeEditor()
                setfileUrl('')
              }} aria-label="delete">
                   <CloseIcon sx={{ color: `${theme==='light'? '#000':'white'}` }} />
              </IconButton>
            </Box>
  
            <Box sx={{height:'100%'}}>
    <div className=" w-100 h-100">
      Edit Document
<div className="MyComponent">
       <div className="webviewer" ref={viewer} style={{height: "100vh"}}></div> 

    </div>
   </div>
   
            </Box>
          </Box>
        </Modal>
      </Box>

   </>
  )
}

export default EditorComponent