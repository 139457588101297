import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Layouts/Header'
import FirstPortion from './FirstPortion';
import SecondPortion from './SecondPortion';
import ThirdSection from './ThirdSection';
import MainFooter from '../../Layouts/MainFooter';
import ForthPortion from './ForthPortion';
import { useLocation } from 'react-router-dom';
export const LandingScreen = () => {

  const firstAreaRef = useRef(null);
  const secondAreaRef = useRef(null);
  const [scrollToggle, setscrollToggle] = useState(false)
  

  const location= useLocation()

  let scrollArea=location?.state?.scrollSection
  console.log(location?.state, "---------");
  useEffect(()=>{
    if(scrollArea){
    setscrollToggle(scrollArea)
console.log('enable toggle');  
    }

  },[scrollArea])

  React.useEffect(() => {
    if (scrollToggle==='feature'&& firstAreaRef.current) {
      firstAreaRef.current.scrollIntoView({ behavior: 'smooth' });
      setscrollToggle(false)
      console.log('first');
    }
  }, [scrollToggle==='feature']);

  // Effect to scroll to the second area when scrollToSecondArea state changes
  React.useEffect(() => {
    if (scrollToggle==='price' && secondAreaRef.current) {
      secondAreaRef.current.scrollIntoView({ behavior: 'smooth' });
      setscrollToggle('')
      console.log("second");
    }
  }, [scrollToggle==='price']);
  return (
    <>
 
  <div style={{overflowX:'hidden'}}>
         
 <FirstPortion/>
       <div ref={firstAreaRef}>
         <SecondPortion />
       </div>
     
  
     
        <div ref={secondAreaRef}>
             <ThirdSection />
        </div>
       
        <div className="container">
           <ForthPortion/>
        </div>
       
  </div>

    </>
   
  )
}
