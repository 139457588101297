import React, { useState } from 'react';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import FileAddButton from './FileAddButton';
import FilesListTable from './FilesListTable';
import { useTheme } from '../../../ThemeProvider';
import {useSelector} from 'react-redux'
import FileGridView from './FileGridView'


export default function FilesScreen({onButtonClick,folderId}) {
const [searchTitle, setsearchTitle] = useState()
const { theme} = useTheme();

    const setSearchTitle=(title)=>{
        setsearchTitle(title)
    }

    const updatedData = useSelector((state) => state.user.userData.userDetail);
 
  return (
    <>
        <div style={{marginBottom:'80px', marginTop:'20px'}}>
        <h3 className='px-5 bdms-title' >Documents</h3>
    </div>
      <div
        className=" my-1 px-md-5 m-0"
        style={{width: "100%" }}
      >
        <div className="row">
     
    
<div className='d-flex' style={{cursor:'pointer'}} typeof='button' onClick={onButtonClick}>
    <KeyboardReturnRoundedIcon className={`${theme==='light'? 'dark-text':'light-text'}`} style={{fontSize:"40px"}} />
    <div className='d-flex align-items-center'>
         <span className='text-muted text-middle px-1'>
           <small className={`${theme==='light'? 'dark-text':'light-text'}`}>Back</small> </span>
    </div>
   
</div>
      
 <FileAddButton setSearchTitle={setSearchTitle} folderId={folderId}/>

       {
      updatedData?.data?.grid_view === 'grid' ? 
             <FileGridView searchTitle = {searchTitle} folderId={folderId}/>
     : 
          <FilesListTable searchTitle = {searchTitle}  folderId={folderId}/> 

        }
        
        </div>
      </div>
    </>
  );
}
