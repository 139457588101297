import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseURL, instance } from "../../axios/axios";

import axios from "axios";
import { toast } from "react-hot-toast";
import { CompressOutlined } from "@mui/icons-material";

export const viewSingleDocuments = createAsyncThunk(
  "documents/viewSingleDocuments",
  async (data, thunkAPI) => {
    const organization_id = localStorage.getItem("orgID");
    try {
      const res = await axios.get(
        `${baseURL}api/workDocs/document/${data?.org_id}/${data?.doc_id}`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete single document
export const deleteDocument = createAsyncThunk(
  "documents/deleteDocument",
  async (data, thunkAPI) => {
    debugger
    try {
      const res = await axios.delete(
        `${baseURL}api/workDocs/document/${data.organization_id}/${data.doc_id}`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all folder of organization
export const GetAllFolder = createAsyncThunk(
  "documents/GetAllFolder",
  async (_id, thunkAPI) => {
    try {
      const res = await axios.get(
        `${baseURL}api/workDocs/folder/${_id}`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create new folder

export const CreateNewFolder = createAsyncThunk(
  "documents/CreateNewFolder",
  async (data, thunkAPI) => {
    const organization_id = localStorage.getItem("orgID");
    try {
      const res = await axios.post(
        `${baseURL}api/workDocs/folder/${organization_id}`,
        data?.data,
        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete new folder

export const DeleteFolder = createAsyncThunk(
  "documents/DeleteFolder",
  async (data, thunkAPI) => {
    try {
      const res = await axios.delete(
        `${baseURL}api/workDocs/folder/${data.organizationId}/${data.folderId}`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all file of specific folder
export const GetAllFiles = createAsyncThunk(
  "documents/GetAllFiles",
  async (data, thunkAPI) => {
    const organization_id = localStorage.getItem("orgID");
    console.log(data, "dta is coming");

    try {
      const res = await axios.get(
        `${baseURL}api/workDocs/folder/${organization_id}/${data.doc_id}`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// send approval to other users

export const SendApproval = createAsyncThunk(
  "documents/SendApproval",
  async (data, thunkAPI) => {
    try {
      const res = await axios.post(
        `${baseURL}api/workDocs/invite/${data?.id}`,
        data?.data,
        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      // Handle 403 Forb_idden error

      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//share document

export const ShareDocument = createAsyncThunk(
  "documents/sendShareDocument",
  async (data, thunkAPI) => {
    debugger
    console.log(' dtata while share the document',data)
    try {
      const res = await axios.post(
        `${baseURL}api/workDocs/share/${data?.id}`,
        data?.data,
        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast.error(error.response.data.message);
      }
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// send approval to other users

export const InvitesDetail = createAsyncThunk(
  "documents/getShareDocument",
  async (id, thunkAPI) => {
    debugger
    console.log(' dtata while share the document',id)
    try {
      const res = await axios.get(
        `${baseURL}api/workDocs/invite/${id}`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete approved document
export const DeleteApprovedDocApi = createAsyncThunk(
  "DeleteApprovedDocApi",
  async (id, thunkAPI) => {
    console.log(' dtata while share the document',id)
    try {
      const res = await axios.delete(`${baseURL}api/workDocs/invite/${id}`, {
        headers: {
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access-token")
          )}`,
        },
      });

      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Approvedocument = createAsyncThunk(
  "documents/ShareDocument",
  async (id, thunkAPI) => {
    console.log(' dtata while share the document',id)
    try {
      const token = JSON.parse(localStorage.getItem("access-token"));
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.put(
        `${baseURL}api/workDocs/invite/${id}`,
        null,
        { headers }
      );

      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ocrToFileApi = createAsyncThunk(
  "documents/ocrToFileApi",
  async (data, thunkAPI) => {
    try {
      const token = JSON.parse(localStorage.getItem("access-token"));
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${baseURL}api/workDocs/textdocument`,
        data,
        { headers }
      );

      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      const message =
        error.response?.data?.error || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSharedDocument = createAsyncThunk(
  "api/workDocs/document/share",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(
        `${baseURL}api/workDocs/share`,

        {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      const message =
        error.response?.data?.error || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState: {
    Documents: {
      getAllFolder: {},
      AllFilesList: [],
      uploadDoc: {},
      sharedDocument: [],
    },
    invitesDetailData: {},
    message: "",
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // get files of specific folder.

    builder.addCase(GetAllFiles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllFiles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Documents.AllFilesList = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(GetAllFiles.rejected, (state, action) => {
      state.isLoading = false;
      state.Documents.AllFilesList = {};
      state.error = action.error.message;
    });

    builder.addCase(ShareDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ShareDocument.fulfilled, (state, action) => {
      state.isLoading = false;

      state.message = action.payload.message;
    });
    builder.addCase(ShareDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.error;
      state.message = action;
    });
    // get all documets cases

    builder.addCase(viewSingleDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewSingleDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.Documents = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(viewSingleDocuments.rejected, (state, action) => {
      state.isLoading = false;
      // state.Documents = {};
      state.error = action.error.message;
    });

    // delete documets from list

    builder.addCase(deleteDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // get list of all folder of organization

    builder.addCase(GetAllFolder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetAllFolder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Documents.getAllFolder = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(GetAllFolder.rejected, (state, action) => {
      state.isLoading = false;
      state.Documents = {};
      state.error = action.error.message;
    });

    // create new folder of organization

    builder.addCase(CreateNewFolder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateNewFolder.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.Documents = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(CreateNewFolder.rejected, (state, action) => {
      state.isLoading = false;
      // state.Documents = {};
      state.error = action.error.message;
    });

    // delete folder

    builder.addCase(DeleteFolder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteFolder.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.Documents = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(DeleteFolder.rejected, (state, action) => {
      state.isLoading = false;
      // state.Documents = {};
      state.error = action.error.message;
    });

    // list of all invites Details

    builder.addCase(InvitesDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(InvitesDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invitesDetailData = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(InvitesDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.invitesDetailData = {};
      state.error = action.error.message;
    });

    //ocr text change into file
    builder.addCase(ocrToFileApi.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ocrToFileApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
    });
    builder.addCase(ocrToFileApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getSharedDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSharedDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.sharedDocument = action.payload;
    });
    builder.addCase(getSharedDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(SendApproval.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(SendApproval.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(SendApproval.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(DeleteApprovedDocApi.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteApprovedDocApi.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(DeleteApprovedDocApi.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default documentSlice.reducer;
