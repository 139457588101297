import { Box, Typography } from "@mui/material";
import {
  BackButton,
  ButtonWrapper,
  FormTitle,
  InputStyle,
  NextButton,
  SubTitle,
} from "./document.style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import userImage from "../../Images/user.png";
import { useContext } from "react";
import { MULTI_STEP_CONTEXT } from "../Context/MultiStepFormContext";
import { useDispatch, useSelector } from "react-redux";
import { SendApproval } from "../../redux/slice/DocumentsCrudSlice";
import { resetPassword } from "../../redux/slice/auth";
import { toast } from "react-hot-toast";
import { useTheme } from '../../ThemeProvider';
import DashButton from "../Buttons/DashButton";
import { GetUserInvitesApi } from "../../redux/slice/organization";

const FormTabFour = ({ handleBack, classes, activeStep,fileData,closeModal,setinitalstate }) => {
  console.log("🚀 ~ FormTabFour ~ fileData:", fileData)
  const { theme} = useTheme();
  const dispatch = useDispatch()
  const {isLoading} = useSelector((state)=>state.document)
  let { file, inputTag, dateAndTime, title, description } =
    useContext(MULTI_STEP_CONTEXT);

  let data = {
    file,
    inputTag,
    dateAndTime,
    title,
    description,
  };



//get the extension of file
  const parts = fileData?.name?.split('.');
  const fileExtension = parts?.length > 1? '.' + parts?.pop():''


  const SubmitData=()=>{
     dispatch(SendApproval({
      id:fileData._id,
      data:{sharedWith:inputTag,
      docName:title,
      docDes:description}
      
     }))
     .then(async (res)=>{  
      res?.payload?.message?.length > 0 && toast.success(res?.payload?.message)
       await dispatch(GetUserInvitesApi())
        setinitalstate()
          closeModal()
          inputTag=[]
          title=''
          description=''
          window.location.reload()
     })
  }

  return (
    <div>


      <Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color:`${theme==='light'? '#000':'white'}`,
            mt: "30px",
          }}
        >
          Files
        </Typography>
        <Box sx={{ mt: "20px" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="modal-table-background"
                sx={{
                 
                  width: "100%",
                }}
              >
                <TableRow >
                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>Name</TableCell>
                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>Last Modified</TableCell>
                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>Size</TableCell>
                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>Kind</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="modal-table-background">
                {/* {rows.map((row) => ( */}
                <TableRow
                // key={rows.name}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ color:`${theme==='light'? '':'white'}`}}>
                    {fileData?.name}
                  </TableCell>
                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>
                    {fileData?.description}
                  </TableCell >

                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>{fileData?.size / 1000}</TableCell>
                  <TableCell sx={{ color:`${theme==='light'? '':'white'}`}}>{fileExtension}</TableCell>
                </TableRow>
                {/* ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box   sx={{
            mt: "10px",
          }}>
          <SubTitle variant="h5" sx={{ color:`${theme==='light'? '':'white'}`}}>Approvers</SubTitle>
        </Box>
        <Box
          sx={{
            mt: "10px",
          }}
        >
          {inputTag?.map((item,i)=>{
            return(
 <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }} key={i}>

          
            <Typography sx={{ color:`${theme==='light'? '':'white'}`}}>{item}</Typography>
          </Box>
            )
          })}
         
          
        </Box>
      </Box>
<hr className="my-2"/>
      <Box>
        <SubTitle variant="h5" sx={{ color:`${theme==='light'? '':'white'}`}}>Title</SubTitle>
        <Typography sx={{ color:`${theme==='light'? '':'white'}`}}>{title}</Typography>
      </Box>
      <hr className="my-2"/>
      <Box sx={{ mt: "20px" }}>
        <SubTitle variant="h5" sx={{ color:`${theme==='light'? '':'white'}`}}>Description</SubTitle>
        <Typography sx={{ color:`${theme==='light'? '':'white'}`}}>
          {description}
        </Typography>
      </Box>

     

      <ButtonWrapper>
       
     
  <DashButton
   disabled={activeStep === 0}
          onClick={handleBack}
          label="Back"
          color='white'

  />
<DashButton
          onClick={SubmitData}
          label={`${isLoading ? "Loading..." : "Send Approval"}`}
          color={'dark'}
          disabled={isLoading}
/>

     
      </ButtonWrapper>
    </div>
  );
};

export default FormTabFour;
