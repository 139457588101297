import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
const dispatch = useDispatch();
  useEffect(() => {
    let login = localStorage.getItem("access-token");
    if (!login) {
      navigate("/");
    }
  },[dispatch]);

  return (
    <>
      <Component />
    </>
  );
};

export default Protected;
