import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURL,FormData } from "../../axios/axios";


const resetPasswordURl = localStorage.getItem("reset-url")
const parsedURL = JSON.parse(resetPasswordURl)


export const signUpUser = createAsyncThunk("authentication/signUpUser", async (data,thunkAPI) => {
    try {
            const res = await axios.post(`${baseURL}api/auth/signUp`, { ...data })

    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})

export const signInUser = createAsyncThunk("authentication/signInUser", async (data, thunkAPI) => {
    try {
      const res = await axios.post(`${baseURL}api/auth/login`, { ...data });
      return res.data;
    } catch (error) {
      const message = error.response?.data?.message || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

export const forgetPassword = createAsyncThunk("authentication/forgetPassword", async (data,thunkAPI) => {

    try {
          const res = await axios.post(`${baseURL}api/auth/forgotPassword`, { ...data })
  
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }
  
})

export const resetPassword = createAsyncThunk("authentication/resetPassword", async (data,thunkAPI) => {


    try {
            const res = await axios.patch(`${baseURL}api/auth/resetPassword/${ data.token }`,data.data)
    
    return res.data

    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})

export const updateProfile = createAsyncThunk("authentication/updateProfile", async ({data, _id},thunkAPI) => {


    try {
            const res = await axios.patch(`${baseURL}api/user/organization/${_id}`,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        { ...data },

    )
    
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }



})

// Get userDetails
export const GetUserDetail = createAsyncThunk("authentication/GetUserDetail", async (_,thunkAPI) => {


    try {
           const res = await axios.get(`${baseURL}api/user/personalDetails`,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        

    )
   
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

 

})


//update user detail
export const UpdateUserDetail = createAsyncThunk("authentication/UpdateUserDetail", async (data,thunkAPI) => {



    try {
        
    const res = await axios.post(`${baseURL}api/user/personalDetails`, data,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`,
                "Content-Type":"application/form-data",
               
            }
        }
        

    )
  
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})


//update password
export const UpdatedPassword = createAsyncThunk("authentication/UpdatedPassword", async (data,thunkAPI) => {


    try {
          const res = await axios.post(`${baseURL}api/auth/updateMyPassword`, data,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        }
        

    )
   
    return res.data
    }   catch (error) {
       toast.error(error?.response?.data?.message)
       const message =
       error.response?.data?.error || error.message || error.toString();
   
     return thunkAPI.rejectWithValue(message);
    }

  

})


// Get userDetails
export const RequestForTwoFA = createAsyncThunk("authentication/RequestForTwoFA", async (_,thunkAPI) => {


    try {
         const res = await axios.get(`${baseURL}api/auth/requestTwoFA`,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        

    )
   
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

   

})




// Get userDetails
export const EnableDisableTwoFA = createAsyncThunk("authentication/EnableDisableTwoFA", async (data,thunkAPI) => {

    try {
          const res = await axios.post(`${baseURL}api/auth/enableDisableTwoFA`,data,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        
    )
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})

// Get all user and organization by Admin
export const AllUserAndOrganization = createAsyncThunk("authentication/AllUserAndOrganization", async (_,thunkAPI) => {

    try {
          const res = await axios.get(`${baseURL}api/user`,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        
    )
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})


// delete user or organization by Admin
export const DeleteUserOROrganization = createAsyncThunk("authentication/DeleteUserOROrganization", async (user_id,thunkAPI) => {

    try {
          const res = await axios.delete(`${baseURL}api/user/${user_id}`,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        
    )
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})


// get users of organization by Admin
export const userOfOrganization = createAsyncThunk("authentication/userOfOrganization", async (org_id,thunkAPI) => {

    try {
          const res = await axios.get(`${baseURL}api/user/${org_id}`,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        
    )
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})


// get users of organization by Admin
export const organizationUsers = createAsyncThunk("api/user/user-of-organization", async (org_id,thunkAPI) => {
            console.log(org_id,'org_id');
            const organization_id =localStorage.getItem('orgID');
    try {
          const res = await axios.get(`${baseURL}api/user/user-of-organization/${organization_id}`)
          console.log(res,'res');
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})


// create user or organization by Admin
export const CreateUserOROrganization = createAsyncThunk("authentication/CreateUserOROrganization", async (data,thunkAPI) => {

    try {
          const res = await axios.post(`${baseURL}api/user`,data,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        
    )
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})


// create user or organization by Admin
export const blockUser = createAsyncThunk("blockUser", async (data,thunkAPI) => {
   console.log(data);
    try {
          const res = await axios.patch(`${baseURL}api/auth/userStatus`,data,

        {
            headers: {
                authorization: `Bearer ${JSON.parse(
                    localStorage.getItem("access-token")
                )}`
            }
        },
        
    )
    return res.data
    }   catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

})

export const CheckEmailValidationApi = createAsyncThunk("authentication/CheckEmailValidationApi", async (data,thunkAPI) => {
     try {
           const res = await axios.post(`${baseURL}api/email`,data,
 
         {
             headers: {
                 authorization: `Bearer ${JSON.parse(
                     localStorage.getItem("access-token")
                 )}`
             }
         },
         
     )
     return res.data
     }   catch (error) {
        toast.error(error?.response?.data?.data)
         const message =
           error.response?.data?.error || error.message || error.toString();
       
         return thunkAPI.rejectWithValue(message);
     }
 
 })





const authSlice = createSlice({
    name: "authentication",
    initialState: {
        isLoading: false,
        userData: {
            userDetail:{},
            RequestToFA:{},
            UserAndOrganizationList:{},
            UpdatedListOfUsersOfOrg:{}
        },
        message: "",
        token: "",
        error: null
    },
    extraReducers: (builder) => {
        /**
         * 
         * signup credential
         */
        builder.addCase(signUpUser.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(signUpUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData = action.payload;
            state.message = action.payload.message
            state.error = null;
           
          
        });
        builder.addCase(signUpUser.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message
            toast.error("User not created please try agin")
        })

        /**
         * 
         * signIn credential
         */
        builder.addCase(signInUser.pending, (state) => {
            state.isLoading = true;
          });
          builder.addCase(signInUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData = action.payload;
            state.error = null;
            state.message = action.payload.message;
            state.token = action.payload.data?.token;
            localStorage.setItem("access-token", JSON.stringify(state?.token));
            toast.success("User successfully logged in");
          });
          builder.addCase(signInUser.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.payload;
            if (action.payload === 'Your account is inactive. Please contact support.') {
              toast.error(action.payload);
            } else {
              toast.error("User credential is wrong! Please try again");
            }
          });

        /**
         * 
         * forgetPassword
         */
        builder.addCase(forgetPassword.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(forgetPassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.userData = action.payload
            state.message = action.payload.message
            localStorage.setItem("reset-url", JSON.stringify(state.message))
            toast.success("Please check your email and verify")
           
        });
        builder.addCase(forgetPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message
            
        })
        /**
         * 
         * reset passowrd
         */
        builder.addCase(resetPassword.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message
       
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
            
        })
        /**
         * 
         * update user
         */
        builder.addCase(updateProfile.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData = action.payload;
            state.message = action.payload.message;
            
        });
        builder.addCase(updateProfile.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message;
            
        })

         /**
         * 
         * get user Detail
         */
         builder.addCase(GetUserDetail.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(GetUserDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData.userDetail = action.payload;
            state.message = action.payload.message;
           
        });
        builder.addCase(GetUserDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.userData.userDetail = {};
            state.error = action.error.message;
           
        })

         /**
         * 
         * update user detail
         */
         builder.addCase(UpdateUserDetail.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(UpdateUserDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData.userDetail = action.payload;
            state.message = action.payload.message;
     
        });
        builder.addCase(UpdateUserDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.userData.userDetail = {};
            state.error = action.error.message;
            
        })

         /**
         * 
         * Request for two factor authentication
         */
         builder.addCase(RequestForTwoFA.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(RequestForTwoFA.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData.RequestToFA = action.payload;
            state.message = action.payload.message;
     
        });
        builder.addCase(RequestForTwoFA.rejected, (state, action) => {
            state.isLoading = false;
            state.userData.RequestToFA = {};
            state.error = action.error.message;
            
        })


          /**
         * 
         * Get all organization and user
         */
          builder.addCase(AllUserAndOrganization.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(AllUserAndOrganization.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userData.UserAndOrganizationList = action.payload;
            state.message = action.payload.message;
     
        });
        builder.addCase(AllUserAndOrganization.rejected, (state, action) => {
            state.isLoading = false;
            state.userData.UserAndOrganizationList = {};
            state.error = action.error.message;
            
        })

            /**
         * 
         * Get all users of organization for Admin
         */
            builder.addCase(userOfOrganization.pending, (state) => {
                state.isLoading = true
            });
            builder.addCase(userOfOrganization.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userData.UpdatedListOfUsersOfOrg = action.payload;
                state.message = action.payload.message;
         
            });
            builder.addCase(userOfOrganization.rejected, (state, action) => {
                state.isLoading = false;
                state.userData.UpdatedListOfUsersOfOrg = {};
                state.error = action.error.message;
                
            })



            builder.addCase(blockUser.pending, (state) => {
                state.isLoading = true
            });
            builder.addCase(blockUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.message = action.payload.message;
         
            });
            builder.addCase(blockUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
                
            })
        

            
            builder.addCase(UpdatedPassword.pending, (state) => {
                state.isLoading = true
            });
            builder.addCase(UpdatedPassword.fulfilled, (state, action) => {
                state.isLoading = false;
                state.message = action.payload.message;
         
            });
            builder.addCase(UpdatedPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
                
            })

             
            builder.addCase(CheckEmailValidationApi.pending, (state) => {
                state.isLoading = true
            });
            builder.addCase(CheckEmailValidationApi.fulfilled, (state, action) => {
                state.isLoading = false;
                state.message = action.payload.message;
         
            });
            builder.addCase(CheckEmailValidationApi.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
                
            })
    }
})


export default authSlice.reducer;