import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import recycle from "../../Images/Recycle.png";
import { useDispatch } from "react-redux";
import { EnableDisableTwoFA } from "../../redux/slice/auth";

const Otp = ({twoFAFormattedKey,toTpURI}) => {
  const [otp, setOtp] = useState();

  const [data, setdata] = useState({
    twoFAFormattedKey,
    toTpURI,
    state:true,
    
  })


  console.log("sendable data", data);
  const dispatch = useDispatch()


  const SubmitData =async()=>{



await dispatch(EnableDisableTwoFA({...data,code:otp}))
  }
console.log(otp);
  return (
    <Box
      sx={{
        height: "600px",
        width: {
          xs: "100%",
          lg: "600px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
         
          padding: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: "40px",
          }}
        >
          <img src={recycle} alt="" />
        </Box>

        <Box>
          <Typography
            sx={{
              color: "#808495",
              fontWeight: "600",
              my: "10px",
              textAlign: "center",
              fontSize: "26px",
            }}
          >
            Please Enter OTP
          </Typography>
          <Typography
            sx={{
              color: "#808495",
              fontWeight: "600",
              my: "10px",
              textAlign: "center",
            }}
          >
            We have sent you one time password to your mobile
          </Typography>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "30px",
            }}
          >
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<>&nbsp; &nbsp; &nbsp;</>}
              placeholder="0"
              inputStyle={{
                width: "48px",
                height: "48px",
                borderRadius: "5px",
                border: "2px solid rgba(67, 66, 93, 0.6)",
                color: "rgba(67, 66, 93, 0.6)",
                fontSize: "26px",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              mt: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#808495", fontSize: "14px" }}
            >
              Didn’t receive the OTP?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#226CFC",
                fontSize: "14px",
                textDecoration: "underline",
              }}
            >
              RESEND
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "#263238",
                color: "#fff",
                textAlign: "center",
                fontSize: "18px",
                marginTop: "30px",
                "&:hover": {
                  background: "#263238",
                },
              }}
              onClick={SubmitData}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Otp;
