
import React, { useEffect, useRef } from "react";
import {

  MDBCardImage,

} from "mdb-react-ui-kit";
import img1 from "../Login/images/img1.png";
import img2 from "../Login/images/img2.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
// import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
// import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "../../redux/slice/auth";
import toast from "react-hot-toast";
import MainButton from "../Buttons/MainButton";
import './style.css'

const styles = {
  height: "800px",
};

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));
const Signup = () => {
  const navigate = useNavigate();
  // const handleClick1 = () => {
  //   debugger;
  //   navigate("/Login");
  // };
  // ==========validation functionality start===========
  const password = useRef({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  // redux action dispatch
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);
  // handle signUp
  const onSubmit = (data) => {
    dispatch(signUpUser(data))
      .then((res) => {
        console.log(res,'res');
        if(res.error){
          toast.error('something went wrong!')
        }
        else{
          toast.success('Please check your Email!')
          navigate('/registrationote', { state: { email: data.email } })
        }
        
      })
    console.log(data)
  };

  useEffect(() => {
    let login = localStorage.getItem("access-token");
    if (login) {
      navigate("/dashboard");
    }
  });

  const passwordValidation = "^(?=.*d)[0-9]{8,}$";
  password.current = watch("password", "");
  // ==========validation functionality end===========

  return (
    <>
      <div className="my-2">
        <div className=" row g-0">
          <div className="col-md-6 order-md-1 order-sm-2 d-none d-sm-block" >
            <MDBCardImage
              src={img2}
              alt="login form"
              className="rounded-start w-100"
              style={styles}
            />
          </div>

          <div className="col-md-6 order-md-2 order-sm-1">
            <div className="d-flex justify-content-center align-items-center" >
              <div className="">

                <div className="d-flex flex-row mt-2 align-items-end custom-login">
                  <img
                    src={img1}
                    alt=""
                    style={{ height: 90, width: 73, marginLeft: "20px" }}
                  />
                  <span className=" logo-font   ml-5" style={{ color: '#43425D', fontSize: '48px', fontWeight: "400" }}>
                    Zetta Circles
                  </span>
                </div>
                <div className="px-5">
                  <p
                    className="fw-normal  montserrat-font"
                    style={{ fontSize: '18px', lineHeight: '47px', letterSpacing: '-0.5px' }}
                  >
                    Please complete to create your account.
                  </p>
                </div>



                <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '0px 30px' }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 6 }}
                  >
                    <Grid item xs={6}>
                      <TextField
                        id="standard-basic"
                        label="First name"
                        variant="standard"
                        {...register("firstName", {
                          required: true,
                          pattern: /^[a-zA-Z]+$/,
                        })}
                      />
                      {errors.firstName &&
                        errors.firstName.type === "required" && (
                          <div className="text-danger">
                            First name is required
                          </div>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="standard-basic"
                        label="Last name"
                        variant="standard"
                        {...register("lastName", {
                          required: true,
                          pattern: /^[a-zA-Z]+$/,
                        })}
                      />
                      {errors.lastName &&
                        errors.lastName.type === "required" && (
                          <div className="text-danger">
                            First name is required
                          </div>
                        )}
                    </Grid>
                  </Grid>
                  {/* ==============row 2================= */}
                  <Grid container>
                    <TextField
                      id="standard-basic"
                      label="Username"
                      variant="standard"
                      fullWidth
                      style={{ margin: "10px 0" }}
                      {...register("name", {
                        required: "Username is required.",
                      })}
                    />
                    {errors.username && (
                      <div className="text-danger">
                        {errors.username.message}
                      </div>
                    )}
                  </Grid>
                  {/* ==============row 3================= */}
                  <Grid container>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      fullWidth
                      style={{ margin: "1px 0" }}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email.message}</div>
                    )}
                  </Grid>
                  {/* ==============row 4================= */}

                  <Grid container>
                    <TextField
                      id="standard-password-input1"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      fullWidth
                      style={{ margin: "3px 0" }}
                      {...register("password", {
                        required: "You must specify a password",
                        pattern: {
                          value: passwordValidation,
                          message:
                            "Password should contain at least one digit, one lower case, one upper case and 8 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="text-danger mt-1">
                        {errors.password.message}
                      </div>
                    )}
                  </Grid>
                  {/* ==============row 5================= */}
                  <Grid container>
                    <TextField
                      id="standard-password-input2"
                      label="Confirm Password"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      fullWidth
                      style={{ marginTop: "5px", marginBottom: "7px" }}
                      {...register("passwordConfirm", {
                        required: "You must specify a password",

                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                    />
                    {errors.passwordConfirm && (
                      <div className="text-danger mb-3">
                        {errors.passwordConfirm.message}
                      </div>
                    )}
                  </Grid>
                  {/* ============================================ */}

                  <div className="row my-4">
                    <div className="col d-flex justify-content-left">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="form1Example3"
                          defaultChecked
                        />
                        <p
                          className="form-check-label m-0 montserrat-font"
                          htmlFor="form1Example3"
                        >
                          I agree with terms and conditions
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 w-100 d-flex justify-content-center">
                    <MainButton type={'submit'} backgroundColor={'rgb(25, 21, 78)'} label={isLoading ? "Loading..." : "SignUp"} />
                  </div>


                </form>
                <p className="text-center montserrat-font">Already have an Account? <span> <Link to="/login"> Sign in</Link></span> </p>





              </div>

            </div>
            <div className=" text-center">     <small className="text-center montserrat-font fs-6 mt-5">Term of use <span> <Link style={{ textDecoration: 'none' }} to="/login"> Privacy Policy</Link></span> </small>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
