import React, { useEffect } from "react";
import "./Dash.css";
import Avatar from "@mui/material/Avatar";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
// import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import emj from "../Images/1.jpg";
import zetLogo from '../../Images/zetLogo.jpeg'
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useTheme } from '../../ThemeProvider';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetail } from "../../redux/slice/auth";
import { Navbarvalue } from '../../HeaderValue';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import toast from "react-hot-toast";
export default function DashTopNav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { updateValue } = Navbarvalue()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const updatedData = useSelector((state) => state.user.userData.userDetail)

  const navigate = useNavigate();
  const { theme, darkTheme, lightTheme } = useTheme();
  // logout user
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetUserDetail())
  }, [])

  const handleLogout = () => {
    localStorage.clear();
    lightTheme()
    updateValue(null)
    navigate("/");
    toast.success('!Logout Successfully')
  };


  useEffect(() => {
    let login = localStorage.getItem("access-token");
    if (!login) {
      navigate("/");
    }
  });

  return (
    <>





            <div class=" profile-option-main mx-3" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="profile-option collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
          <div className="d-flex mt-1">
             <Avatar
                  alt="Remy Sharp"
                  src={updatedData?.data?.organizationLogo ? updatedData?.data?.organizationLogo : updatedData?.data?.logo ? updatedData?.data?.logo : zetLogo}
                  style={{ color: "red", height: 30, width: 30 }}
                />
                <div className="d-flex align-items-center justify-content-center mx-2">
                  <div >
                     <p className="m-0 general-text" 
                 
                    style={{
                     
                      color: `${theme === 'light' ? 'white' : 'white'}`
                    }}
                  >
                    {updatedData?.data?.name ? 
                    updatedData?.data?.name?.length>10 ? `${updatedData?.data?.name?.slice(0,10)}...`: updatedData?.data?.name 
                    : 'User Name'}
                  </p>
                  </div>
                </div>

                <div className="d-flex ms-1 justify-content-end align-items-center option-profile-icon">
                  <KeyboardArrowDownOutlinedIcon style={{color:'white'}}/>
                </div>
                 

          </div>
           
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
         
        >
          <div class="accordion-body px-1">
            <div className="d-flex ps-2 my-2" style={{cursor:'pointer'}} onClick={handleLogout }>
              <LogoutOutlinedIcon/>
                <p class='mx-2 general-text'>Logout</p> 
            </div>
          
          </div>
        </div>
      </div>
    </div>

            

        
    </>
  );
}
