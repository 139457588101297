import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURL } from "../../axios/axios";






//add contact information
export const AddContactInfoApi = createAsyncThunk("AddContactInfoApi", async (data,thunkAPI) => {

    try {
      
    const res = await axios.post(`${baseURL}api/contact`, data,
    {
        headers: {
            authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access-token")
            )}`
        }
    })
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})


//get all contact information
export const GetAllContactInfoApi = createAsyncThunk("GetAllContactInfo", async (_,thunkAPI) => {

    try {
      
    const res = await axios.get(`${baseURL}api/contact`,
    {
        headers: {
            authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access-token")
            )}`
        }
    })
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})

//get contact detail
export const GetContactDetailApi = createAsyncThunk("GetContactDetailApi", async (id,thunkAPI) => {

    try {
      
    const res = await axios.post(`${baseURL}api/contact/${id}`, 
    {
        headers: {
            authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access-token")
            )}`
        }
    })
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})


const ContactSlice = createSlice({
    name: "Contact",
    initialState: {
        isLoading: false,
        complainData: {},
        message: "",
        token: "",
        error: null
    },
    extraReducers: (builder) => {
      
//add contact us information and send to superadmin
        builder.addCase(AddContactInfoApi.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(AddContactInfoApi.fulfilled, (state, action) => {
            state.isLoading = false;
            
        });
        builder.addCase(AddContactInfoApi.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
            
        })

        //get all contact us information
        builder.addCase(GetAllContactInfoApi.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(GetAllContactInfoApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.complainData = action.payload;
            
        });
        builder.addCase(GetAllContactInfoApi.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
            
        })
      

                //contact detail
                builder.addCase(GetContactDetailApi.pending, (state) => {
                    state.isLoading = true
                });
                builder.addCase(GetContactDetailApi.fulfilled, (state, action) => {
                    state.isLoading = false;
                    
                });
                builder.addCase(GetContactDetailApi.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.error.message;
                    
                })
        
    }
})


export default ContactSlice.reducer