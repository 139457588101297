import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURL } from "../../axios/axios";

const token = localStorage.getItem("access-Token")
const parsedToken = JSON.parse(token)

// create new user by organization
export const createUser = createAsyncThunk("organization/createUser", async (data,thunkAPI) => {
    try {
          let axiosConfig = {
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${parsedToken}`,
        },
    };

    const res = await axios.post(`${baseURL}api/user/organization`, data, axiosConfig)
    
    return res.data

    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

  
})
// get all user of organization
export const getAllUsers = createAsyncThunk("organization/getAllUsers", async (_,thunkAPI) => {


    try {
         let axiosConfig = {
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${parsedToken}`,
        },
    };

    const res = await axios.get(`${baseURL}api/user/organization`, axiosConfig)
   
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

   

})



// email verifiction of user
export const EmailVerification = createAsyncThunk("organization/EmailVerification", async (id,thunkAPI) => {


    try {
            const res = await axios.get(`${baseURL}api/auth/emailConfirmation/${id}`)

    
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

 



})



// email verifiction of user
export const updateOneTimePassword = createAsyncThunk("organization/updateOneTimePassword", async (data,thunkAPI) => {



    try {
        
    let axiosConfig = {
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${data?.token}`,
        },
    };
  
    const res = await axios.post(`${baseURL}api/auth/updateOneTimePassword`,data?.data, axiosConfig)
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})


// get all user of organization
export const SendQueryApi = createAsyncThunk("SendQueryApi", async (data,thunkAPI) => {


    try {
         

    const res = await axios.post(`${baseURL}api/user/contact-us`,data)
   
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }

   

})

export const GetAllQueries = createAsyncThunk("GetAllQueries", async (_,thunkAPI) => {



    try {
        
   
  
    const res = await axios.get(`${baseURL}api/user/fetch-query`, 
    {
        headers: {
            authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access-token")
            )}`
        }
    })
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})

//get organization users
export const GetOrgUserApi = createAsyncThunk("GetOrgUserApi", async (_,thunkAPI) => {



    try {
        
   
  
    const res = await axios.get(`${baseURL}api/user/organization`, 
    {
        headers: {
            authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access-token")
            )}`
        }
    })
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})


//get organization users
export const GetUserInvitesApi = createAsyncThunk("GetUserInvites", async (_,thunkAPI) => {

    try {
        
   
  
    const res = await axios.get(`${baseURL}api/workDocs/invite`, 
    {
        headers: {
            authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access-token")
            )}`
        }
    })
  
    return res.data
    }  catch (error) {
        const message =
          error.response?.data?.error || error.message || error.toString();
      
        return thunkAPI.rejectWithValue(message);
    }


})

const organizationSlice = createSlice({
    name: "organization",
    initialState: {
        isLoading: false,
        orgainzationData: {},
        AllQueries:{},
        OrgUsers:{},
        Approval:{},
        message: "",
        token: "",
        error: null
    },
    extraReducers: (builder) => {
        /**   
         * 
         * create user
         */
        builder.addCase(createUser.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgainzationData = action.payload;
            state.message = action.payload.message;
            
        });
        builder.addCase(createUser.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message;
            
        })
        /**   
         * 
         * get all users
         */
        builder.addCase(getAllUsers.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgainzationData = action.payload;
            state.message = action.payload.message;
        });
        builder.addCase(getAllUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message;
            
        })




          /**   
         * 
         * get all users
         */
          builder.addCase(updateOneTimePassword.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(updateOneTimePassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgainzationData = action.payload;
            state.message = action.payload.message;
        });
        builder.addCase(updateOneTimePassword.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message;
            
        })




        builder.addCase(SendQueryApi.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(SendQueryApi.fulfilled, (state, action) => {
            state.isLoading = false;
          
            state.message = action.payload.message;
        });
        builder.addCase(SendQueryApi.rejected, (state, action) => {
            state.isLoading = false;
            state.userData = {};
            state.error = action.error.message;
            
        })


        
        
        
        builder.addCase(GetAllQueries.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(GetAllQueries.fulfilled, (state, action) => {
            state.isLoading = false;
            state.AllQueries = action.payload;
            state.message = action.payload.message;
        });
        builder.addCase(GetAllQueries.rejected, (state, action) => {
            state.isLoading = false;
           
            state.error = action.error.message;
            
        })

        builder.addCase(GetOrgUserApi.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(GetOrgUserApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.OrgUsers = action.payload;
            state.message = action.payload.message;
        });
        builder.addCase(GetOrgUserApi.rejected, (state, action) => {
            state.isLoading = false;
           
            state.error = action.error.message;
            
        })
    


        
        builder.addCase(GetUserInvitesApi.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(GetUserInvitesApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.Approval = action.payload;
            state.message = action.payload.message;
        });
        builder.addCase(GetUserInvitesApi.rejected, (state, action) => {
            state.isLoading = false;
           
            state.error = action.error.message;
            
        })
    
    }
})


export default organizationSlice.reducer