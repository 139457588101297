import React, { useState,useEffect } from 'react'

import { useSelector,useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode';
import { GetAllFolder } from '../../../redux/slice/DocumentsCrudSlice';
import DeleteModal from './DeleteModal';
import { useTheme } from '../../../ThemeProvider';
import '../Doc.css'
import {ReactComponent as Folder1} from "../../../assets/folderIcons/Folder1.svg";
import {ReactComponent as Folder2} from "../../../assets/folderIcons/Folder2.svg";
import {ReactComponent as Folder3} from "../../../assets/folderIcons/Folder3.svg";
import {ReactComponent as Folder4} from "../../../assets/folderIcons/Folder4.svg";
import {ReactComponent as Folder5} from "../../../assets/folderIcons/Folder5.svg";
import {ReactComponent as Folder6} from "../../../assets/folderIcons/Folder6.svg";
import {ReactComponent as Folder0} from "../../../assets/folderIcons/Folder0.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const FolderGridView = ({sendDocIdToParent,filesScreen,searchTitle}) => {

    const [allDoc, setallDoc] = useState()
    console.log("🚀 ~ FolderGridView ~ allDoc:", allDoc)
    const [DeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [docId, setdocId] = useState()

  const { theme} = useTheme();

  const updatedAllDoc = useSelector((state)=>state.document.Documents.getAllFolder)

  const sendData = (id) => {
    sendDocIdToParent(id); // Call the callback function and pass the docId
  };

  let user = JSON.parse(
    localStorage.getItem("access-token")
  )

  const token = user;
  const decodedToken = jwtDecode(token);
  const organizationId = decodedToken.id

  function DeleteModalScreen() {
    setDeleteModalOpen(!DeleteModalOpen)
  }

  //get all document api
  const dispatch = useDispatch()
const getAllDoc = async()=>{
 const response= await dispatch(GetAllFolder(organizationId))
const getValues =response.payload
 await setallDoc(getValues)

}
//  console.log(allDoc,"++++++++++");
useEffect(()=>{
  getAllDoc()
},[])


useEffect(() => {
  if(updatedAllDoc){
    setallDoc(updatedAllDoc)
  }

},[updatedAllDoc])


useEffect(() => {
    if (allDoc?.data && searchTitle) {
      const filteredResults = allDoc.data.filter((item) =>
        item.name.toLowerCase().includes(searchTitle.toLowerCase())
      );
      setallDoc((prev) => ({ ...prev, data: filteredResults }));
    } else {
      getAllDoc();
    }
  }, [searchTitle]);


  const folderImages = [Folder1, Folder2, Folder3, Folder4, Folder5, Folder6, Folder0];

  const getRandomFolderImage = () => {
    const randomIndex = Math.floor(Math.random() * folderImages.length);
    return folderImages[randomIndex];
  };
  return (
    <div className='row p-0 my-5 mx-3'>
        {
            allDoc?.data?.filter(item => item.description !== "first folder")?.map((item,i)=>{
                const formattedDate = new Date(item?.updatedAt).toLocaleDateString('en', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  });


                  const name = item?.name?.length>11 ? `${item?.name?.slice(0,11)}...`: item?.name
                return (
                    <div className="col-md-3 col-sm-1" key={i}  style={{cursor:'pointer'}}  >
                        <div  className={`card border-0  bg-white mx-3 shadow-sm ${theme==='light'? '':'theme-back'}`}  >
            <div className='d-flex justify-content-end my-2'>
   
<div class="dropdown">
 <MoreVertIcon style={{cursor:'pointer'}} 
            data-bs-toggle="dropdown" aria-expanded="false"
            className={`dropdown-toggle ${theme==='light'? 'pic-ver fs-2':'text-white fs-2'}`} 
      />
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#"  
            onClick={()=>{
                    DeleteModalScreen()
                    setdocId(item?.id)
                  }} >Delete</a></li>
    
  </ul>
</div>
                
           
            </div>
            <div className='d-flex justify-content-center'>


            <div className="" 
             onClick={()=>{
              filesScreen()
              sendData(item.id)
              }}>
          {/* Use the dynamically generated SVG component */}
          {React.createElement(getRandomFolderImage(),{
      style: { width: '90px', cursor:'pointer' }} // Set the desired width
    )}
        </div>
                     
      
            </div>
            <div  className='d-flex justify-content-center mt-md-3'   onClick={()=>{
                      filesScreen()
                      sendData(item.id)
                      }}>
            <h4 style={{color:'#43425D',fontSize:'18px',fontFamily:'GoogleSans,Sans-serif',fontWeight:'600',textTransform:'capitalize',cursor:'pointer'}}
            className={` ${theme==='light'? '':'text-white'}`} 
           
            >
               {name}
            </h4>
            </div>
             <div  className='d-flex justify-content-center'>
           <p  style={{fontSize:'11px',opacity:'1px'}} className={` ${theme==='light'? 'text-muted':'text-white'}`} >
                Modified {formattedDate}
            </p>
             </div>
           
        </div>    
                    </div>
                 
                )
            })
        }
       

       <DeleteModal ID ={docId}
       closeModal={DeleteModalScreen}
       ModalIsOpen={DeleteModalOpen}
      />
    </div>
  )
}

export default FolderGridView