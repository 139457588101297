import { Box, IconButton, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { toast } from "react-hot-toast";
import jwtDecode from "jwt-decode";

import { useTheme } from "../../../ThemeProvider";
import { viewSingleDocuments } from "../../../redux/slice/DocumentsCrudSlice";
import { baseURL } from "../../../axios/axios";

import { useDispatch } from "react-redux";
import PDFReader from "./DocViewers/pdfviewer/PDFReader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "90%",
    minWidth: "60%",
    maxHeight: "90%",
    minHeight: "60%",
    transform: "translate(-50%, -50%)",
  },
};

export default function ViewDocModal({ ModalIsOpen, closeModal, fileData }) {
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  let user = JSON.parse(localStorage.getItem("access-token"));

  const token = user;
  const decodedToken = jwtDecode(token);
  const org_id = decodedToken.organization;

  const handleOnError = (e) => {
    console.error("Error loading file:", e);
    setloading(false);
  };

  const handleOnLoad = () => {
    setloading(false);
  };

  console.log(fileData, "fileData");

  const parts = fileData?.name?.split(".");
  const fileExtension = parts?.length > 1 ? "." + parts?.pop() : "";
  const docViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${fileData?.doc}`;

  return (
    <Box>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <h3
              style={{
                textAlign: "left",
                color: `${theme === "light" ? "" : "white"}`,
              }}
            >
              View Document
            </h3>
            <IconButton
              onClick={() => {
                closeModal();
              }}
              aria-label="delete"
            >
              <CloseIcon
                sx={{ color: `${theme === "light" ? "#000" : "white"}` }}
              />
            </IconButton>
          </Box>

          <Box
            variant="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box variant="div" className="text-end w-100 h-100">
              <div className="w-100 h-100">
                <Box sx={{ height: "100%" }}>
                  <div className=" w-100 h-100">
                    {fileExtension &&
                      (() => {
                        switch (fileExtension) {
                          case ".docx":
                          case ".xlsx":
                          case ".xls":
                          case ".pptx":
                          case ".doc":
                            return (
                              <iframe
                                src={docViewerUrl}
                                width="100%"
                                height="500px"
                                frameBorder="0"
                              />
                            );
                          case ".png":
                          case ".jpg":
                          case ".jpeg":
                            return (
                              <div className="d-flex justify-content-center align-items-center">
                                <img src={fileData?.doc} alt="" />
                              </div>
                            );
                          case ".pdf":
                            return <PDFReader fileUrl={fileData?.doc} />;
                          default:
                            return "Coming soon";
                        }
                      })()}
                  </div>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
